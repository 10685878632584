import LazyLoad from './lazyload.js';
import './preload.js';
//import './swup.js';
import './splide.js';
import '../css/entry.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import interactionPlugin from '@fullcalendar/interaction';
import io from 'socket.io-client';
import gsap from 'gsap';
import { property } from 'lodash';
//import 'glightbox/dist/css/glightbox.min.css';
//import GLightbox from './glightbox.js';


window.addEventListener('pageshow', function () {
    setTimeout(function () {
        var preloader = document.getElementById('preloader')
        if (preloader) { preloader.classList.add('preloader-hide'); }
    }, 150);
});

//Removing Preloader
setTimeout(function () {
    var preloader = document.getElementById('preloader')
    if (preloader) { preloader.classList.add('preloader-hide'); }
}, 150);

// Function to show preloader
function showPreloader() {
    var preloader = document.getElementById('preloader');
    if (preloader) {
        preloader.classList.remove('preloader-hide');
    }
}
/*
if (typeof WURFL !== "undefined" && WURFL.is_mobile === false) {
    // User is on a desktop, so apply the mobile frame
    var bodyContent = document.body.innerHTML;
    document.body.innerHTML = '<div class="mobile-frame">' + bodyContent + '</div>';
}
*/
// Using beforeunload event
//window.addEventListener('beforeunload', showPreloader);

// Using pagehide event
window.addEventListener('pagehide', showPreloader);



document.addEventListener('DOMContentLoaded', () => {
    'use strict'

    //Global Variables
    let isAJAX = false; // AJAX transitions. Requires local server or server
    var pwaName = "HomeHarmony"; //Local Storage Names for PWA
    let isPWA = false;  // Enables or disables the service worker and PWA
    var pwaRemind = 3; //Days to re-remind to add to home
    var pwaNoCache = true; //Requires server and HTTPS/SSL. Will clear cache with each visit

    //Setting Service Worker Locations scope = folder | location = service worker js location
    var pwaScope = "../";
    var pwaLocation = "../_service-worker.js";



    //Place all your custom Javascript functions and plugin calls below this line
    async function init_template() {

        setTimeout(function () {
            var preloader = document.getElementById('preloader')
            if (preloader) { preloader.classList.add('preloader-hide'); }
        }, 150);

        setTimeout(() => {
            const timestampElement = document.getElementById('timestamp-scroll-hook');
            if (timestampElement) {
                timestampElement.scrollIntoView({ behavior: 'smooth', block: 'end' }); // Consider using 'end' to ensure visibility
            }
        }, 200);


        //Caching Global Variables
        var i, e, el; //https://www.w3schools.com/js/js_performance.asp

        //Attaching Menu Hider
        var menuHider = document.getElementsByClassName('menu-hider');
        if (!menuHider.length) { var hider = document.createElement('div'); hider.setAttribute("class", "menu-hider"); document.body.insertAdjacentElement('beforebegin', hider); }
        setTimeout(function () { if (hider.classList.contains('menu-active')) { hider.classList.remove('menu-active'); } }, 50);

        //Activating Menus
        document.querySelectorAll('.menu').forEach(el => { el.style.display = 'block' })


        function setupFileUpload(triggerSelector, fileInputSelector, uploadEndpoint, onSuccess, onError, beforeUpload, afterUpload, additionalData = {}) {
            const trigger = document.getElementById(triggerSelector);
            const fileInput = document.getElementById(fileInputSelector);

            trigger.addEventListener('click', () => {
                fileInput.click();
                if (beforeUpload) {
                    beforeUpload(); // Execute before upload actions, such as showing loading animations
                }
            });

            fileInput.addEventListener('change', function (event) {
                if (this.files.length > 0) {
                    const file = this.files[0];
                    uploadFile(file, uploadEndpoint, onSuccess, onError, afterUpload, additionalData);
                }
            });
        }

        function uploadFile(file, uploadEndpoint, onSuccess, onError, afterUpload, additionalData = {}) {
            const formData = new FormData();
            formData.append('image', file); // Make sure to align the 'image' key with your server-side code
            console.log([...formData]);

            // Append additional data to formData
            Object.keys(additionalData).forEach(key => {
                formData.append(key, additionalData[key]);
            });

            const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
            fetch(uploadEndpoint, {
                method: 'POST',
                headers: {
                    'x-csrf-token': csrfToken
                },
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (onSuccess) onSuccess(data);
                    if (afterUpload) afterUpload();
                })
                .catch(error => {
                    if (onError) onError(error);
                    if (afterUpload) afterUpload();
                });
        }

        function showLoader() {
            document.getElementById('loader').style.display = 'block';
        }

        // Function to hide loader
        function hideLoader() {
            document.getElementById('loader').style.display = 'none';
        }

        // Reusable function to perform async operations
        function performAsyncOperation(element, asyncOperation) {
            // Prevent multiple clicks
            if (element.classList.contains('custom-disabled')) {
                return;
            }

            // Add disabled class and show loader
            element.classList.add('custom-disabled');
            showLoader();

            asyncOperation()
                .then(data => {
                    // Handle the response data
                    console.log(data);
                })
                .catch(error => {
                    // Handle any errors
                    console.error('Error:', error);
                })
                .finally(() => {
                    hideLoader();
                    element.classList.remove('custom-disabled');
                });
        }

        //if statement to see if the element with the id #intro1 exists
        if (document.querySelector('#intro1')) {

            const button = document.getElementById('submit-address');

            document.addEventListener('showVerifyAddressMenu', showVerifyAddressMenu);

            function showVerifyAddressMenu() {
                const address = document.getElementById('location-input').value;
                const locality = document.getElementById('locality-input').value;
                const administrative_area_level_1 = document.getElementById('administrative_area_level_1-input').value;
                const postal_code = document.getElementById('postal_code-input').value;

                const completeAddress = `${address} <br> ${locality}, ${administrative_area_level_1} ${postal_code}`;

                document.getElementById('confirm-address').innerHTML = completeAddress;
                menu('menu-verify-address', 'show', 250);
            }

            function handleVerification(event) {
                event.preventDefault(); // Prevent default form submission

                button.textContent = 'Verifying...';
                button.disabled = true;

                // Collect the form data
                const formData = new FormData(document.getElementById('address-form'));

                // Retrieve CSRF token value
                const csrfToken = document.querySelector('input[name="_csrf"]').value;

                // AJAX request to the server
                fetch('/submit-address', {
                    method: 'POST',
                    headers: {
                        'x-csrf-token': csrfToken, // Include CSRF token as a header
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(formData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            if (data.isLocal) {
                                // If address is verified
                                button.innerHTML = '<i class="fas fa-thumbs-up"></i> Continue';
                                button.className = 'w-100 btn btn-l btn-success rounded-sm btn-full text-uppercase font-800 btn-icon'; // Change button color
                                // Display a success message or take further actions
                            } else {
                                // If address is not in the service area
                                button.innerHTML = '<i class="fas fa-thumbs-down"></i> Not Yet, Continue Anyway';
                                button.className = 'w-100 btn btn-l btn-danger rounded-sm btn-full text-uppercase font-800 btn-icon'; // Change button color
                            }
                            // Update the button's event listener for the next action
                            button.removeEventListener('click', handleVerification);
                            button.addEventListener('click', () => window.location.href = '/intro2');
                        } else {
                            // If address is not in the service area
                            button.textContent = 'Something Broke, Oops.';
                            button.className = 'btn btn-danger rounded-sm btn-full text-uppercase font-800 btn-icon'; // Change button color
                            // Display an error message or take further actions
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        button.textContent = 'Error';
                        button.className = 'btn btn-danger rounded-sm btn-full text-uppercase font-800 btn-icon'; // Change button color
                        // Optionally display an error message
                    })
                    .finally(() => {
                        button.disabled = false; // Re-enable the button
                        // Optionally hide the loading animation
                    });
            };
            button.addEventListener('click', handleVerification);
        }

        //if statement to see if the element with the id #intro2 exists
        if (document.querySelector('#intro2')) {
            const homeRadio = document.getElementById('house');
            const strRadio = document.getElementById('str');
            const rentalsRadio = document.getElementById('rentals');
            const businessRadio = document.getElementById('business');
            const images = document.querySelectorAll('.img-fluid-radio');

            const changeImage = (imageId) => {
                images.forEach((img) => {
                    if (img.classList.contains('show')) {
                        img.classList.add('slide-out');
                        img.addEventListener('transitionend', () => {
                            img.classList.remove('slide-out');
                        }, { once: true });
                    }
                    img.classList.remove('show');
                    img.classList.remove('slide-in');
                    // Add 'show' class and 'slide-in' class to the image with the corresponding ID
                    if (img.id === imageId) {
                        img.classList.add('show');
                        img.classList.add('slide-in');
                    }
                });
            };
            // Event listeners for radio buttons
            homeRadio.addEventListener('change', () => changeImage('house-img'));
            strRadio.addEventListener('change', () => changeImage('str-img'));
            rentalsRadio.addEventListener('change', () => changeImage('rentals-img'));
            businessRadio.addEventListener('change', () => changeImage('business-img'));

            const continueIntro2 = document.getElementById('continue-intro2');
            const form = document.getElementById('usage-form');
            const phoneInput = document.getElementById("phone");

            function validatePhone() {
                const phoneRegex = /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/;
                if (phoneRegex.test(phoneInput.value)) {
                    continueIntro2.disabled = false;
                    continueIntro2.classList.remove("bg-dark-light");
                    continueIntro2.classList.add("bg-blue-dark");
                } else {
                    continueIntro2.disabled = true;
                    continueIntro2.classList.remove("bg-blue-dark");
                    continueIntro2.classList.add("bg-dark-light", "color-white");
                }
            }

            phoneInput.addEventListener("input", validatePhone);

            form.addEventListener("submit", (e) => {
                e.preventDefault();
                form.submit();
            });
        }

        if (document.querySelector('#intro3')) {
            function whenIsAppointment(appointmentDate) {
                const oneWeek = 7 * 24 * 60 * 60 * 1000;
                const now = new Date();
                const nextWeek = new Date(now.getTime() + oneWeek);
                const inTwoWeeks = new Date(now.getTime() + 2 * oneWeek);

                if (appointmentDate < nextWeek) return "this";
                if (appointmentDate < inTwoWeeks) return "next";
                return "in 2 weeks on";
            }
            function getAmOrPmFromDate(dateObject) {
                const hour = dateObject.getHours();
                return hour >= 12 ? 'PM' : 'AM';
            }
            function formatHour(timeString) {
                // Extract the hour from the time string
                let hour = parseInt(timeString.split(':')[0], 10);

                // Convert to 12-hour format and remove leading zero
                hour = hour % 12;
                if (hour === 0) hour = 12; // Handle midnight

                return hour;
            }
            let flipCompleted = false;

            const appointmentCards = document.querySelectorAll('.appointmentCard');

            appointmentCards.forEach(appointmentCard => {
                let cardClicked = false;

                const cardClickHandler = (event) => {
                    if (cardClicked) return;
                    cardClicked = true;
                    event.stopPropagation();
                    event.preventDefault(); // Prevent normal form submission

                    // Start the flip animation
                    const flipAnimation = gsap.to(appointmentCard, {
                        rotationX: '+=360',
                        repeat: -1, // Infinite repeat
                        duration: 1,
                        ease: 'power2.inOut',
                        onComplete: () => {
                            flipCompleted = true;
                        }
                    });

                    // Highlight the clicked card
                    appointmentCard.classList.add('active-appointment-card');

                    // Animation for other cards
                    appointmentCards.forEach(card => {
                        if (card !== appointmentCard) {
                            gsap.to(card, {
                                height: 0, // Shrink height
                                duration: .75, // Duration of animation
                                ease: 'power2.out', // Type of easing
                                onComplete: () => {
                                    card.style.display = 'none'; // Hide the card after animation
                                }
                            });
                        }
                    });

                    const targetFormId = appointmentCard.getAttribute('data-target-form');
                    const form = document.getElementById(targetFormId);
                    const formData = new FormData(form);
                    const searchParams = new URLSearchParams();

                    for (const pair of formData) {
                        searchParams.append(pair[0], pair[1]);
                    }

                    fetch(form.action, {
                        method: 'POST',
                        body: searchParams,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                if (!appointmentCard.hasAttribute('data-cancel-button-added')) { }
                                const bookingText = `Booked, see you ${whenIsAppointment(new Date(data.jsDate))} ${data.dayOfWeek} at ${formatHour(data.time)}${getAmOrPmFromDate(new Date(data.jsDate))}.`;
                                //appointmentCard.querySelector('p').innerText = bookingText; // Update the card text

                                const signupButton = document.getElementById('finished-signup');
                                if (signupButton) {
                                    signupButton.innerText = 'See You Soon - Done'; // Change the text to FINISHED
                                    signupButton.classList.remove('bg-dark-light'); // Remove the old background class
                                    signupButton.classList.add('color-white', 'font-600', 'pb-5', 'mb-2', 'pt-3'); // Add the new background class
                                }
                                gsap.to(signupButton, {
                                    backgroundColor: "#B0E298", // First color
                                    duration: 1.2,
                                    repeat: -1, // Repeat indefinitely
                                    yoyo: true, // Alternate between colors
                                    ease: "power1.inOut"
                                });

                                // Additional GSAP animation to transition to second color
                                gsap.to(signupButton, {
                                    backgroundColor: "#6c915a", // Second color
                                    duration: 1.2,
                                    repeat: -1,
                                    yoyo: true,
                                    ease: "power1.inOut",
                                    delay: 1.2 // Delay to sync with the first animation
                                });

                                const updateCardContent = () => {
                                    gsap.to(appointmentCard.querySelectorAll('.card-center'), {
                                        opacity: 0,
                                        duration: 0.5,
                                        onComplete: () => {
                                            while (appointmentCard.firstChild) {
                                                if (appointmentCard.firstChild.className !== 'card-overlay bg-black opacity-70') {
                                                    appointmentCard.removeChild(appointmentCard.firstChild);
                                                } else {
                                                    break;
                                                }
                                            }

                                            const newContainer = document.createElement('div');
                                            newContainer.className = 'card-center';


                                            const newHeader = document.createElement('h2');
                                            //newHeader.className = 'color-white' ;
                                            //add multiple classes to newHeader
                                            //set an id to h2 element newHeader
                                            newHeader.id = 'new-header';
                                            newHeader.classList.add('color-white', 'ms-3');
                                            newHeader.textContent = bookingText;

                                            newContainer.appendChild(newHeader);
                                            appointmentCard.insertBefore(newContainer, appointmentCard.firstChild);

                                            // Fade in the updated content
                                            gsap.to(appointmentCard.querySelectorAll('.card-center'), {
                                                opacity: 1,
                                                duration: 0.5
                                            });
                                            appointmentCard.removeEventListener('click', cardClickHandler);

                                        }
                                    });
                                };

                                if (flipCompleted) {
                                    updateCardContent();
                                } else {
                                    flipAnimation.eventCallback('onComplete', updateCardContent);
                                    flipAnimation.repeat(0); // Finish the current rotation
                                }

                                const buttonWrapper = document.createElement('div');
                                buttonWrapper.classList.add('d-flex', 'justify-content-center', 'align-items-center');
                                buttonWrapper.style.height = '0'; // Initially set height to 0
                                buttonWrapper.style.overflow = 'hidden'; // Hide content during animation

                                const cancelButton = document.createElement('button');
                                cancelButton.innerText = 'Cancel Appointment';
                                cancelButton.classList.add('btn', 'btn-danger', 'btn-xs', 'rounded-sm'); // Add appropriate classes
                                cancelButton.style.overflow = 'hidden'; // Hide content during animation

                                buttonWrapper.appendChild(cancelButton);

                                appointmentCard.after(buttonWrapper);

                                gsap.to(buttonWrapper, {
                                    height: 'auto',
                                    duration: 0.75
                                });

                                cancelButton.addEventListener('click', function (cancelEvent) {
                                    cancelEvent.stopPropagation(); // Prevent the card click event

                                    const csrfToken = document.querySelector('meta[name="_csrf"]').content;
                                    console.log('CSRF token:', csrfToken);

                                    const cancelBtn = this;

                                    fetch('/cancel-initial-visit', {
                                        method: 'POST',
                                        headers: {
                                            'x-csrf-token': csrfToken,
                                            'Content-Type': 'application/json'
                                        },
                                        // No need to send appointmentId in the body
                                    })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                const newHeader = document.getElementById('new-header');
                                                if (newHeader) {
                                                    newHeader.textContent = '😔 Cancelled';
                                                }

                                                // Remove or disable the cancel button
                                                cancelBtn.remove(); // or button.disabled = true; to disable it

                                                console.log(data.message); // Success message
                                            } else {
                                                // Handle failure (e.g., show error message)
                                                console.log(data.message); // Failure message
                                            }
                                        })
                                        .catch(error => {
                                            console.error('Error:', error);
                                            // Handle network error
                                        });
                                });
                            }
                        })
                        .catch(error => {
                            // handle errors
                            console.error(error);
                            flipAnimation.kill();
                            gsap.set(appointmentCard, { rotationX: 0 });
                        });
                }
                appointmentCard.addEventListener('click', cardClickHandler);
                //add event click listener for #finished-signup button to navigate to /chat
                document.getElementById('finished-signup').addEventListener('click', function () {
                    window.location.href = '/todo-list';
                });
            });
        }


        if (document.querySelector('#recurring-appointments')) {

            const moreDatesButton = document.getElementById('more-apts');

            if (moreDatesButton) {
                moreDatesButton.addEventListener('click', function () {
                    let currentPage = parseInt(this.getAttribute('data-current-page'));
                    const maxPage = parseInt(this.getAttribute('data-max-page'));
                    const nextPage = currentPage + 1 > maxPage ? 1 : currentPage + 1; // Cycle back to 1 if end reached

                    // Hide all appointment groups
                    document.querySelectorAll('.appointment-group').forEach(group => {
                        group.classList.add('d-none');
                    });

                    // Show the next group
                    document.querySelectorAll(`.page-${nextPage}`).forEach(group => {
                        group.classList.remove('d-none');
                    });

                    // Update the data-current-page attribute to the new page number
                    this.setAttribute('data-current-page', nextPage.toString());
                });
            }



            function whenIsAppointment(appointmentDate) {
                const oneWeek = 7 * 24 * 60 * 60 * 1000;
                const now = new Date();
                const nextWeek = new Date(now.getTime() + oneWeek);
                const inTwoWeeks = new Date(now.getTime() + 2 * oneWeek);

                if (appointmentDate < nextWeek) return "this";
                if (appointmentDate < inTwoWeeks) return "next";
                return "in 2 weeks on";
            }
            function getAmOrPmFromDate(dateObject) {
                const hour = dateObject.getHours();
                return hour >= 12 ? 'PM' : 'AM';
            }
            function formatHour(timeString) {
                // Extract the hour from the time string
                let hour = parseInt(timeString.split(':')[0], 10);

                // Convert to 12-hour format and remove leading zero
                hour = hour % 12;
                if (hour === 0) hour = 12; // Handle midnight

                return hour;
            }
            let flipCompleted = false;

            const appointmentCards = document.querySelectorAll('.appointmentCard');

            appointmentCards.forEach(appointmentCard => {
                let cardClicked = false;

                const cardClickHandler = (event) => {
                    if (cardClicked) return;
                    cardClicked = true;
                    event.stopPropagation();
                    event.preventDefault(); // Prevent normal form submission

                    // Start the flip animation
                    const flipAnimation = gsap.to(appointmentCard, {
                        rotationX: '+=360',
                        repeat: -1, // Infinite repeat
                        duration: 1,
                        ease: 'power2.inOut',
                        onComplete: () => {
                            flipCompleted = true;
                        }
                    });

                    // Highlight the clicked card
                    appointmentCard.classList.add('active-appointment-card');

                    // Animation for other cards
                    appointmentCards.forEach(card => {
                        if (card !== appointmentCard) {
                            gsap.to(card, {
                                height: 0, // Shrink height
                                duration: .75, // Duration of animation
                                ease: 'power2.out', // Type of easing
                                onComplete: () => {
                                    card.style.display = 'none'; // Hide the card after animation
                                }
                            });
                        }
                    });

                    const targetFormId = appointmentCard.getAttribute('data-target-form');
                    const form = document.getElementById(targetFormId);
                    const formData = new FormData(form);
                    const searchParams = new URLSearchParams();

                    for (const pair of formData) {
                        searchParams.append(pair[0], pair[1]);
                    }

                    fetch(form.action, {
                        method: 'POST',
                        body: searchParams,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                if (!appointmentCard.hasAttribute('data-cancel-button-added')) { }
                                const bookingText = `Booked, see you ${whenIsAppointment(new Date(data.jsDate))} ${data.dayOfWeek} at ${formatHour(data.time)}${getAmOrPmFromDate(new Date(data.jsDate))}.`;
                                //appointmentCard.querySelector('p').innerText = bookingText; // Update the card text

                                const updateCardContent = () => {
                                    gsap.to(appointmentCard.querySelectorAll('.card-center'), {
                                        opacity: 0,
                                        duration: 0.5,
                                        onComplete: () => {
                                            while (appointmentCard.firstChild) {
                                                if (appointmentCard.firstChild.className !== 'card-overlay bg-black opacity-70') {
                                                    appointmentCard.removeChild(appointmentCard.firstChild);
                                                } else {
                                                    break;
                                                }
                                            }

                                            const newContainer = document.createElement('div');
                                            newContainer.className = 'card-center';

                                            const newHeader = document.createElement('h2');
                                            //newHeader.className = 'color-white' ;
                                            //add multiple classes to newHeader
                                            //set an id to h2 element newHeader
                                            newHeader.id = 'new-header';
                                            newHeader.classList.add('color-white', 'ms-3');
                                            newHeader.textContent = bookingText;

                                            newContainer.appendChild(newHeader);
                                            appointmentCard.insertBefore(newContainer, appointmentCard.firstChild);

                                            // Fade in the updated content
                                            gsap.to(appointmentCard.querySelectorAll('.card-center'), {
                                                opacity: 1,
                                                duration: 0.5
                                            });
                                            appointmentCard.removeEventListener('click', cardClickHandler);

                                        }
                                    });
                                };

                                if (flipCompleted) {
                                    updateCardContent();
                                } else {
                                    flipAnimation.eventCallback('onComplete', updateCardContent);
                                    flipAnimation.repeat(0); // Finish the current rotation
                                }

                                const buttonWrapper = document.createElement('div');
                                buttonWrapper.classList.add('d-flex', 'justify-content-center', 'align-items-center');
                                buttonWrapper.style.height = '0'; // Initially set height to 0
                                buttonWrapper.style.overflow = 'hidden'; // Hide content during animation

                                const cancelButton = document.createElement('button');
                                cancelButton.innerText = 'Cancel Appointment';
                                cancelButton.classList.add('btn', 'btn-danger', 'btn-xs', 'rounded-sm'); // Add appropriate classes
                                cancelButton.style.overflow = 'hidden'; // Hide content during animation

                                if (moreDatesButton) {
                                    //hide the more dates button
                                    moreDatesButton.style.display = 'none';
                                }


                                buttonWrapper.appendChild(cancelButton);

                                appointmentCard.after(buttonWrapper);

                                gsap.to(buttonWrapper, {
                                    height: 'auto',
                                    duration: 0.75
                                });



                                cancelButton.addEventListener('click', function (cancelEvent) {
                                    cancelEvent.stopPropagation(); // Prevent the card click event

                                    const csrfToken = document.querySelector('meta[name="_csrf"]').content;
                                    console.log('CSRF token:', csrfToken);

                                    const cancelBtn = this;

                                    // Replace 'yourVisitType' with the appropriate variable that holds the visit type
                                    const visitType = document.getElementById('visitType').value;

                                    fetch('/cancel-appointment?visitType=' + visitType, {
                                        method: 'POST',
                                        headers: {
                                            'x-csrf-token': csrfToken,
                                            'Content-Type': 'application/json'
                                        },
                                        // No need to send appointmentId in the body
                                    })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                const newHeader = document.getElementById('new-header');
                                                if (newHeader) {
                                                    newHeader.textContent = '😔 Cancelled';
                                                }

                                                // Remove or disable the cancel button
                                                cancelBtn.remove(); // or button.disabled = true; to disable it

                                                console.log(data.message); // Success message
                                            } else {
                                                // Handle failure (e.g., show error message)
                                                console.log(data.message); // Failure message
                                            }
                                        })
                                        .catch(error => {
                                            console.error('Error:', error);
                                            // Handle network error
                                        });
                                });
                            }
                        })
                        .catch(error => {
                            // handle errors
                            console.error(error);
                            flipAnimation.kill();
                            gsap.set(appointmentCard, { rotationX: 0 });
                        });
                }
                appointmentCard.addEventListener('click', cardClickHandler);
                //appointmentCard.querySelector('.card-overlay').addEventListener('click', cardClickHandler);
                //add event click listener for #finished-signup button to navigate to /chat
                document.getElementById('return-to-chat').addEventListener('click', function () {
                    window.location.href = '/todo-list';
                });
            });


        }
        /******************************************************************************************************************
         * ****************************************************************************************************************
         * *********************************   CHAT PAGE  STARTS HERE    **************************************************
         * ****************************************************************************************************************
         * ****************************************************************************************************************
         */
        if (document.querySelector('#chat')) {


            /******************************************************************************************************************
             *           RESIZE PAGE CONTENT TO FIT SCREEN
             */


            function getElementHeight(elementId) {
                const element = document.getElementById(elementId);
                return element ? element.offsetHeight : 0;
            }

            function adjustContentHeight() {
                const headerHeight = getElementHeight('header'); // Dynamically get header height
                const footerHeight = getElementHeight('footer-bar'); // Dynamically get footer height
                const windowHeight = window.innerHeight;
                const contentHeight = windowHeight - headerHeight - footerHeight;

                const pageContent = document.getElementById('chat');
                pageContent.style.height = `${contentHeight}px`;
                pageContent.style.overflowY = 'auto'; // Ensures content is scrollable
            }

            // Initial adjustment
            adjustContentHeight();

            // Adjust on resize (keyboard show/hide)
            window.addEventListener('resize', adjustContentHeight);

            /******************************************************************************************************************
             *           RESIZE MSG INPUT TO FIT CONTENT
             */

            const inputBox = document.getElementById('msg');

            function resizeInput() {
                const baseHeight = 45; // Initial height in px
                const step = 25; // Step size in px

                // Temporarily shrink to get the correct new scrollHeight
                inputBox.style.height = 'auto';

                let newHeight = baseHeight;
                if (inputBox.scrollHeight > 62) {
                    // Calculate the number of steps to increase
                    const stepsToAdd = Math.ceil((inputBox.scrollHeight - 60) / step);
                    newHeight += stepsToAdd * step;
                }

                // Set the new height, but don't exceed max-height
                inputBox.style.height = `${Math.min(newHeight, 250)}px`;
            }

            // Add event listener for input events
            inputBox.addEventListener('input', resizeInput);



            /******************************************************************************************************************
             *           VOICE RECORDING LOGIC
             */

            let micPermissionButton = document.getElementById('mic-permission-button');
            if (micPermissionButton) {
                micPermissionButton.addEventListener('click', requestMicAccess);
            }
            /*
                        async function checkMicPermission() {
                            const permissions = await navigator.permissions.query({ name: 'microphone' });
                            console.log(permissions.state);
                            return permissions.state;
                        }
            */
            /*async function requestMicAccess() {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    stream.getTracks().forEach(track => track.stop());

                    let collapseElement = new bootstrap.Collapse(document.getElementById('mic-permission-collapse'), { toggle: false });
                    collapseElement.hide();

                } catch (err) {
                    console.error("Error accessing microphone:", err);
                }
            }
*/
            async function requestMicAccess() {
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    // Process the stream or indicate permission granted
                    stream.getTracks().forEach(track => track.stop()); // Stop tracks if you're not using the stream immediately

                    let collapseElement = new bootstrap.Collapse(document.getElementById('mic-permission-collapse'), { toggle: false });
                    collapseElement.hide();
                } catch (err) {
                    console.error("Error accessing microphone:", err);
                    // Handle the case where the user denies access or an error occurs
                    let collapseElement = new bootstrap.Collapse(document.getElementById('mic-permission-collapse'), { toggle: false });
                    collapseElement.show();
                }
            }
            /*           checkMicPermission().then(state => {
                           const micBtn = document.getElementById('micBtn');
                           if (state === "granted") {
                               // Remove the data-bs-toggle attribute from the button
                               micBtn.removeAttribute('data-bs-toggle');
                           } else if (state === "prompt") {
                               let collapseElement = new bootstrap.Collapse(document.getElementById('mic-permission-collapse'), { toggle: false });
                               collapseElement.show();
                           }
                       });
           */
            let isRecording = false;
            let isLoading = false;
            let mediaRecorder;
            let recordedChunks = [];

            function toggleRecordingUI(isRecording, isLoading) {
                const micBtn = document.getElementById('micBtn');
                const micIcon = document.getElementById('micIcon');
                const titleContainer = micBtn.closest('.ps-3.pe-3.pt-2.mb-4').querySelector('.flex-grow-1.align-self-center.ps-2');
                const title = titleContainer.querySelector('h1');
                const subtitle = titleContainer.querySelector('p');

                if (isRecording) {
                    isLoading = false;
                    // Replace mic icon with a black square
                    micIcon.classList.add('fa-stop');
                    micIcon.classList.remove('fa-microphone', 'bg-red-dark');
                    micIcon.classList.add('bg-gray-light', 'color-black');

                    // Replace title with soundwave animation
                    title.innerHTML = '<div class="swBoxContainer mx-5">' +
                        '<div class="swBox swBox1"></div>' +
                        '<div class="swBox swBox2"></div>' +
                        '<div class="swBox swBox3"></div>' +
                        '<div class="swBox swBox4"></div>' +
                        '<div class="swBox swBox5"></div>' +
                        '</div>';
                    subtitle.style.display = 'none';
                } else if (isLoading) {
                    isRecording = false;
                    // Update for loading state
                    micIcon.classList.remove('fa-stop', 'bg-gray-light', 'color-black');
                    micIcon.style.display = 'none'; // Hide the microphone icon

                    // Replace title with loading animation
                    title.innerHTML = '<div class="loading">' +
                        '<div></div><div></div><div></div>' +
                        '</div>';
                    subtitle.style.display = 'none';

                } else {
                    // Reset mic icon to microphone
                    micIcon.classList.remove('fa-stop', 'bg-gray-light', 'color-black');
                    micIcon.classList.add('fa-microphone', 'bg-red-dark');

                    // Reset title to "Walk & Talk"
                    title.textContent = 'Walk & talk';
                    subtitle.style.display = 'block';
                }
            }

            micBtn.addEventListener('click', async () => {
                if (!isRecording) {
                    // Attempt to start recording
                    try {
                        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                        setupMediaRecorder(stream);
                        startRecording();
                    } catch (error) {
                        console.error("Error accessing microphone:", error);
                        handleMicAccessDenied();
                    }
                } else {
                    // Stop recording
                    stopRecording();
                }
            });

            function setupMediaRecorder(stream) {
                mediaRecorder = new MediaRecorder(stream);

                mediaRecorder.ondataavailable = event => {
                    recordedChunks.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    await uploadRecording();
                    recordedChunks = []; // Reset the chunks array for the next recording
                };
            }

            function startRecording() {
                mediaRecorder.start();
                isRecording = true;
                toggleRecordingUI(isRecording);
            }

            function stopRecording() {
                mediaRecorder.stop();
                mediaRecorder.stream.getTracks().forEach(track => track.stop());
                isRecording = false;
                toggleRecordingUI(isRecording);
            }

            async function uploadRecording() {
                const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
                const blob = new Blob(recordedChunks, { type: 'audio/ogg; codecs=opus' });
                const data = new FormData();
                data.append('audio', blob);
                data.append('_csrf', csrfToken);

                try {
                    const response = await fetch('/audioUpload', { method: 'POST', body: data });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    console.log('Success:', await response.json());
                } catch (error) {
                    console.error('Error:', error);
                }
            }

            function handleMicAccessDenied() {
                // Handle the UI or logic when mic access is denied.
                // You might want to show a message to the user or log the event.
                console.log("Microphone access denied by the user.");
            }
            /*            micBtn.addEventListener('click', async function () {
                            let micPermission = await checkMicPermission();
                            if (micPermission === "granted") {
                                if (isRecording) {
                                    // Stop recording
                                    mediaRecorder.stop();
                                    mediaRecorder.stream.getTracks().forEach(track => track.stop());
                                    isRecording = false;
                                    isLoading = true;
            
                                    // Update UI to show black square and remove soundwave animation
                                    toggleRecordingUI(isRecording, isLoading);
                                } else {
                                    try {
                                        // Start recording
                                        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                                        mediaRecorder = new MediaRecorder(stream);
            
                                        mediaRecorder.start(); // Add this line to start recording
            
                                        // Push the audio chunks into the array as they come in
                                        mediaRecorder.ondataavailable = function (event) {
                                            recordedChunks.push(event.data);
                                        };
            
                                        // Do something with the audio data when recording stops
                                        mediaRecorder.onstop = function () {
                                            const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
                                            const blob = new Blob(recordedChunks, { 'type': 'audio/ogg; codecs=opus' });
                                            const data = new FormData();
                                            data.append('audio', blob);
                                            data.append('_csrf', csrfToken);
            
            
            
                                            fetch('/audioUpload', { method: 'POST', body: data })
                                                .then(response => {
                                                    if (!response.ok) {
                                                        throw new Error('Network response was not ok');
                                                    }
                                                    return response;
                                                })
                                                .then(data => {
                                                    console.log('Success:', data);
                                                })
                                                .catch((error) => {
                                                    console.error('Error:', error);
                                                });
            
                                            recordedChunks = []; // reset the array for the next recording
                                        };
            
                                        // Update UI to show microphone and soundwave animation
                                        isRecording = true;
                                        isLoading = false;
                                        toggleRecordingUI(isRecording, isLoading);
                                    } catch (err) {
                                        console.error("Error accessing microphone:", err);
                                    }
                                }
                            } else {
                                // If the mic permission is not granted, first expand the collapse and request permission
                                let collapseElement = new bootstrap.Collapse(document.getElementById('mic-permission-collapse'), { toggle: false });
                                collapseElement.show();
                                await requestMicAccess();
            
                                // Check the permission again
                                micPermission = await checkMicPermission();
                                if (micPermission === "granted") {
                                    // If the permission is granted, collapse and begin recording
                                    collapseElement.hide();
                                    // Call the micBtn click event again to start the recording process
                                    micBtn.click();
                                } else {
                                    // If the permission is not granted, collapse and abort
                                    collapseElement.hide();
                                }
                            }
                        });
            */
            function highlightElement(selector) {
                const element = document.querySelector(selector);
                const rect = element.getBoundingClientRect();
                const overlay = document.createElement('div');

                overlay.style.position = 'fixed';
                overlay.style.top = `${rect.top}px`;
                overlay.style.left = `${rect.left}px`;
                overlay.style.width = `${rect.width}px`;
                overlay.style.height = `${rect.height}px`;
                overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
                overlay.style.opacity = 1;
                overlay.style.pointerEvents = 'none';
                overlay.style.zIndex = 1002;
                overlay.style.borderRadius = window.getComputedStyle(element).borderRadius;

                element.overlay = overlay;
                document.body.appendChild(overlay);

                element.style.zIndex = 1001;
                gsap.to(overlay, { opacity: 0, duration: 1 });
                gsap.fromTo(
                    element,
                    { boxShadow: "0 0 0 0 rgba(176, 226, 152, 0.7)" },
                    { boxShadow: "0 0 30px 10px rgba(176, 226, 152, 0.7)", duration: 1, yoyo: true, repeat: -1 }
                );
            }
            function animateMicIcon() {
                const micIcon = document.querySelector('#micIconTutorial');
                micIcon.classList.remove('d-none'); // Remove the d-none class to make the element visible

                // Fade in the mic icon
                gsap.fromTo(
                    micIcon,
                    { opacity: 0 },
                    { opacity: 1, duration: 1 }
                );

                // Animate the box-shadow
                gsap.fromTo(
                    micIcon,
                    { boxShadow: "0 0 0 0 rgba(176, 226, 152, 0.5)" },
                    { boxShadow: "0 0 20px 7px rgba(176, 226, 152, 0.5)", duration: 1, yoyo: true, repeat: -1 }
                );
            }
            function fadeOutMicIcon() {
                const micIcon = document.querySelector('#micIconTutorial');

                // Fade out the mic icon
                gsap.to(
                    micIcon,
                    {
                        opacity: 0,
                        duration: 1,
                        onComplete: () => {
                            micIcon.classList.add('d-none'); // Add the d-none class to hide the element after fading out
                        },
                    }
                );

                // Clear the box-shadow animation
                gsap.killTweensOf(micIcon, { boxShadow: true });
                micIcon.style.boxShadow = ''; // Reset the box-shadow to its initial state
            }

            function unhighlightElement(selector) {
                const element = document.querySelector(selector);
                const overlay = element.overlay;

                if (!overlay) {
                    return;
                }

                gsap.killTweensOf(element);
                gsap.to(overlay, {
                    opacity: 1, duration: 1, onComplete: () => {
                        element.style.zIndex = 1;
                        element.style.boxShadow = 'none';
                        document.body.removeChild(overlay);
                        element.overlay = null;
                    }
                });
            }

            function extractCustomerId() {
                const pathSegments = window.location.pathname.split('/');
                // Check if the URL follows the pattern for an admin accessing a chat
                if (pathSegments.length >= 4 && pathSegments[1] === 'employee' && pathSegments[2] === 'chat') {
                    return pathSegments[3]; // customerId is the fourth segment in the URL
                }
                return null; // Return null if the URL doesn't include a customerId
            }

            const customerId = extractCustomerId();

            if (customerId) {
                console.log('Customer ID:', customerId);
            } else {
                // Check if the tutorial has been shown
                function checkTutorialStatus() {
                    console.log(localStorage.getItem('tutorialShown'));
                    return localStorage.getItem('tutorialShown') === 'true';
                }

                // Set tutorial as shown
                function setTutorialShown() {
                    localStorage.setItem('tutorialShown', 'true');
                }
                function showTutorial() {
                    if (!checkTutorialStatus()) {
                        //var tl = gsap.timeline({ paused: true });
                        var step = 0;
                        const overlay = document.querySelector(".overlay");

                        function nextStep() {
                            step++;
                            switch (step) {
                                case 1:
                                    document.getElementById("tutorialHeader").textContent = "Task Addition, Made Easy";
                                    document.getElementById("tutorialMessage").textContent = " Add tasks via AI chat, voice recording, or by uploading a picture of your handwritten list. Our AI may seek additional details for clarity.";
                                    highlightElement("#footer-bar");
                                    animateMicIcon()
                                    break;
                                case 2:
                                    unhighlightElement("#footer-bar");
                                    fadeOutMicIcon();
                                    document.getElementById("tutorialHeader").textContent = "Preparedness Is Key"
                                    document.getElementById("tutorialMessage").textContent = "A detailed to-do list is essential—it enables us to be fully equipped for each job.";
                                    highlightElement(".speech-bubble");
                                    break;
                                case 3:
                                    unhighlightElement(".speech-bubble");
                                    document.getElementById("tutorialHeader").textContent = "Initial Visit, A Must"
                                    document.getElementById("tutorialMessage").textContent = "Book your first appointment to activate your subscription. This visit helps us understand your regular needs.";
                                    document.getElementById("nextButton").style.display = "none";
                                    document.getElementById("doneButton").classList.remove("d-none");
                                    break;
                            }
                        }

                        document.getElementById("nextButton").addEventListener('click', nextStep);

                        document.getElementById("doneButton").addEventListener('click', function () {
                            document.getElementById("tutorialOverlay").style.display = 'none';
                            setTutorialShown()
                            gsap.killTweensOf('#nextButton');
                            gsap.killTweensOf('#doneButton');
                        });

                        gsap.fromTo("#nextButton",
                            { backgroundColor: "#ac92ec" },
                            { backgroundColor: "#7b53dd", duration: 1, yoyo: true, repeat: -1 }
                        );
                        gsap.fromTo("#doneButton",
                            { backgroundColor: "#B0E298" },
                            { backgroundColor: "#071108", duration: 1, yoyo: true, repeat: -1 }
                        );
                    } else {
                        document.getElementById("tutorialOverlay").style.display = 'none';
                    }
                }
                showTutorial();
            }


            var _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

            let socket;
            let isAdmin = false;
            let isProcessing = false;
            let messageQueue = [];

            if (customerId) {
                // Initialize the socket connection with customerId for admin
                socket = io({
                    query: {
                        customerId: customerId
                    }
                });
                socket.on('connect', () => {
                    socket.emit('admin join', { userId: userId, customerId: customerId });
                });
                isAdmin = true;
            } else {
                socket = io();
                socket.on('connect', () => {
                    socket.emit('new user', { id: userId });
                });
            }

            socket.on('error', (error) => { console.error('Socket.IO error:', error) });

            socket.on('loading', (data) => {
                const loadingDiv = document.querySelector('.typing-indicator');
                console.log('Loading:', data);
                if (data.processing) {
                    isProcessing = true;
                    showHarmonyLoadingAnimation();
                } else {
                    isProcessing = false;
                    console.log('Removing loading animation');
                    if (loadingDiv) {
                        loadingDiv.remove();
                    }
                    // Check if there are queued messages to send
                    if (messageQueue.length > 0) {
                        // Combine all queued messages into a single message
                        const combinedMessage = messageQueue.join("\n");

                        // Clear the queue
                        messageQueue = [];

                        // Send the combined message
                        socket.emit('user message', { message: combinedMessage, userId: userId });
                    }
                }
            });

            socket.on('room_joined', (message) => {
                console.log(message)
            });

            socket.on('ai-event-response', (aiResponse) => {
                const loadingDiv = document.querySelector('.typing-indicator');
                if (loadingDiv) {
                    loadingDiv.remove();
                }
                console.log('Received AI response:', aiResponse);
                if (isLoading) {
                    isLoading = false;
                    toggleRecordingUI(isRecording, isLoading);
                }
                let messageObj = {
                    sentBy: 'harmony',
                    created_at: new Date(),
                    message: aiResponse
                }
                outputMessage(messageObj);
            });

            socket.on('admin response', (data) => {
                const { message, sentBy } = data;
                console.log('Received admin response:', message);

                let messageObj = {
                    sentBy: sentBy, // 'admin'
                    created_at: new Date(),
                    message: message
                };
                outputMessage(messageObj);
            });

            socket.on('ai-response', (data) => {
                const { message, sentBy } = data;
                console.log('Received AI response:', message);

                let messageObj = {
                    sentBy: sentBy,
                    created_at: new Date(),
                    message: message
                };
                outputMessage(messageObj);
            });

            socket.on('chat message', (data) => {
                const { message, sentBy } = data;
                // Process and display the message on the admin UI
                let messageObj = {
                    sentBy: sentBy, // 'admin'
                    created_at: new Date(),
                    message: message
                };
                outputMessage(messageObj);
            });

            socket.on('image processing complete', (data) => {
                console.log(data.message); // Log the message for debugging, optional
                isProcessing = false;
                // Process queued messages now that image processing is complete
                if (messageQueue.length > 0) {
                    const combinedMessage = messageQueue.join("\n");
                    messageQueue = []; // Clear the queue after processing

                    console.log("Processing queued messages after image processing");
                    socket.emit('user message', { message: combinedMessage, userId: userId });
                }
            });


            socket.on('user image', (data) => {
                isProcessing = true;
                const loadingDiv = document.querySelector('.typing-indicator');
                let messageObj = {
                    sentBy: 'user',
                    created_at: new Date(),
                    message: data
                }
                if (loadingDiv) {
                    loadingDiv.remove();
                }
                outputMessage(messageObj);
                showHarmonyLoadingAnimation();

            });

            function sendMessage() {
                const msgInput = document.getElementById('msg');
                const msg = msgInput.value.trim();
                if (!msg) return;

                let messageObj = {
                    sentBy: isAdmin ? 'admin' : 'user',
                    created_at: new Date(),
                    message: msg,
                };

                if (isProcessing) {
                    // Add message to the queue if AI or another process is ongoing
                    messageQueue.push(msg);
                } else {
                    if (isAdmin) {
                        socket.emit('admin message', { message: msg, customerId: customerId });
                    } else {
                        console.log("Emitting user message: ", msg);
                        socket.emit('user message', { message: msg, userId: userId });
                    }
                }

                msgInput.value = ''; // Clear input after sending or queueing
            }

            document.getElementById('send-button').addEventListener('click', (event) => {
                event.preventDefault();
                sendMessage();
            });

            document.getElementById('msg').addEventListener('keydown', checkEnter);
            function checkEnter(event) {
                // Function to detect enter key press
                if (event.keyCode === 13) {
                    sendMessage();
                    event.preventDefault();
                }
            }
            let lastMessageTime = null; // Global or higher scope variable

            function outputMessage(messageObj) {
                console.log('Outputting message:', messageObj);
                const chatContainer = document.getElementById('chat-messages');
                // Check if we need to add a timestamp
                const messageTime = new Date(messageObj.created_at);
                if (!lastMessageTime || (messageTime - lastMessageTime) > 3600000) { // 3600000 ms = 1 hour
                    const timeDiv = document.createElement('p');
                    timeDiv.classList.add('text-center', 'mb-0', 'font-11');
                    timeDiv.textContent = formatTimestamp(messageTime);
                    chatContainer.appendChild(timeDiv);
                }
                // Update the last message time
                lastMessageTime = messageTime;
                // Create the speech bubble div
                const div = document.createElement('div');
                div.classList.add('speech-bubble');
                // Add classes based on who sent the message
                if (messageObj.sentBy === 'harmony') {
                    div.classList.add('speech-right', 'bg-magenta-chat'); // Modify as per the receiver's style
                } else if (messageObj.sentBy === 'admin') {
                    div.classList.add('speech-right', 'bg-admin-chat');
                } else {
                    div.classList.add('speech-left', 'color-black'); // Modify as per the sender's style
                }
                // Append the speech bubble to the chat container
                chatContainer.appendChild(div);


                if (typeof messageObj.message === 'object' && (messageObj.message.imageUrl || messageObj.message.thumbnailUrl)) {
                    const filename = messageObj.message.imageUrl.split('/').pop().split('.')[0];
                    // Handling image object
                    const anchor = document.createElement('a');
                    anchor.href = messageObj.message.imageUrl; // Full-size image URL for the href
                    anchor.classList.add(`img-${filename}`);
                    anchor.setAttribute('data-gallery', 'gallery1'); // Set the data-gallery attribute

                    const img = document.createElement('img');
                    img.classList.add('rounded-m', 'mx-n1');
                    img.src = messageObj.message.thumbnailUrl; // Thumbnail URL for the src
                    img.alt = "Image"; // Add an appropriate alt attribute

                    anchor.appendChild(img); // Append the img to the anchor element
                    div.appendChild(anchor); // Append the anchor to the speech bubble div


                } else {
                    div.innerHTML = messageObj.message.replace(/\n/g, '<br>');
                }
                div.scrollIntoView({ behavior: 'smooth', block: 'start' });
                // Create and append a clearfix div after each message
                const clearfix = document.createElement('div');
                clearfix.classList.add('clearfix');
                document.getElementById('chat-messages').appendChild(clearfix);

            }

            function formatTimestamp(date) {
                // Format the date as needed, for example:
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            }

            // Adding a function to output loading animation for Harmony's side
            function showHarmonyLoadingAnimation() {
                const chatContainer = document.getElementById('chat-messages');
                const typingIndicator = document.createElement('div');
                typingIndicator.classList.add('typing-indicator', 'speech-right');

                // Create the dots for the typing indicator
                for (let i = 0; i < 3; i++) {
                    const dot = document.createElement('span');
                    dot.classList.add('harmony-typing-dot');
                    typingIndicator.appendChild(dot);
                }

                chatContainer.appendChild(typingIndicator);
                // Scroll to the bottom of the chat container
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }

            function showUserLoadingAnimation() {
                const chatContainer = document.getElementById('chat-messages');
                const typingIndicator = document.createElement('div');
                typingIndicator.classList.add('typing-indicator', 'speech-left');

                // Create the dots for the typing indicator
                for (let i = 0; i < 3; i++) {
                    const dot = document.createElement('span');
                    dot.classList.add('user-typing-dot');
                    typingIndicator.appendChild(dot);
                }

                chatContainer.appendChild(typingIndicator);
                // Scroll to the bottom of the chat container
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }

            // Assuming 'socket' is your Socket.IO client instance
            socket.on('tasks_update', (data) => {
                if (isLoading) {
                    isLoading = false;
                    toggleRecordingUI(isRecording, isLoading);
                }

                console.log('Received new tasks:', data);
                if (data && data.task) {
                    const newTask = data.task
                    const userTaskId = data.userTaskId;
                    appendNewTask(newTask, userTaskId); // Update the tasks on the front-end
                } else {
                    console.log('undefined task received?');
                }
            });

            socket.on('tasks_update_chat', (data) => {

                console.log('Received new tasks:', data);
                if (data && data.task) {
                    const newTask = data.task
                    const userTaskId = data.userTaskId;
                    appendNewTask(newTask, userTaskId); // Update the tasks on the front-end
                } else {
                    console.log('undefined task received?');
                }
            });

            function appendNewTask(newTask, userTaskId) {
                console.log('Received new tasks:', newTask);

                // First, check if the "No Tasks" content exists and remove it
                const noTasksContent = document.querySelector('#no-tasks-content');
                if (noTasksContent) {
                    noTasksContent.remove();
                }

                // Check if the active tasks card exists
                let activeTasksCard = document.querySelector('#active-tasks-card');
                if (!activeTasksCard) {
                    // If the card doesn't exist, create it and add it to the appropriate place in the DOM
                    activeTasksCard = document.createElement('div');
                    activeTasksCard.className = 'card card-style';
                    activeTasksCard.id = 'active-tasks-card';

                    const contentDiv = document.createElement('div');
                    contentDiv.className = 'content my-0';
                    activeTasksCard.appendChild(contentDiv);

                    const dividerElement = document.querySelector('.divider.divider-margins.mb-0');
                    if (dividerElement) {
                        dividerElement.parentNode.insertBefore(activeTasksCard, dividerElement.nextSibling);
                    } else {
                        console.error('Suitable location for active tasks card not found!');
                        return;
                    }
                }

                // Ensure the task list container exists within the card
                let tasksContainer = document.querySelector('#active-tasks-card .list-group');
                if (!tasksContainer) {
                    tasksContainer = document.createElement('div');
                    tasksContainer.className = 'list-group list-custom-small list-icon-0';
                    activeTasksCard.querySelector('.content.my-0').appendChild(tasksContainer);
                }

                // Create the new task element
                const taskElement = document.createElement('a');
                taskElement.setAttribute('href', `/tasks/${userTaskId}`);
                taskElement.classList.add('d-flex', 'align-items-center');

                // Add task icon
                const taskIcon = document.createElement('i');
                let iconClass = 'fa ' + (newTask.taskType === 'approved' ? 'fa-square' : 'fa-diamond');
                let bgColor = newTask.priority === 'low' ? 'bg-gray-light' :
                    newTask.priority === 'medium' ? 'bg-yellow-light' : 'bg-orange-light';
                taskIcon.className = `font-14 ${iconClass} ${bgColor} rounded-xl`;
                taskElement.appendChild(taskIcon);

                // Add task description
                const taskDescription = document.createElement('span');
                taskDescription.classList.add('flex-grow-1', 'px-2');
                taskDescription.style.wordWrap = 'break-word';
                taskDescription.style.lineHeight = 'normal';
                taskDescription.textContent = newTask.description;
                taskElement.appendChild(taskDescription);

                // Add task arrow icon
                const taskArrowIcon = document.createElement('i');
                taskArrowIcon.className = 'fa fa-angle-right';
                taskElement.appendChild(taskArrowIcon);

                // Append the new task element to the tasks container
                tasksContainer.appendChild(taskElement);
            }


            // Initialize upload functionality for "Photo" input
            setupFileUpload(
                'photoMenuItem', // Trigger element ID
                'photoInput', // File input element ID
                '/tasks/uploadImage', // Adjust this to your endpoint for photo uploads
                data => {
                    console.log('Success:', data);
                    // Update any UI component or trigger any event post success
                },
                error => {
                    console.error('Error:', error);
                },
                () => {
                    // Before upload actions
                    isProcessing = true; // Set processing flag before upload
                    showUserLoadingAnimation(); // Show loading animation
                    menu('menu-upload', 'hide', 250);
                },
                () => { }
            );

            // Initialize upload functionality for "Camera" input
            setupFileUpload(
                'cameraMenuItem', // Trigger element ID
                'cameraInput', // File input element ID
                '/tasks/uploadImage', // Adjust this to your endpoint for camera uploads
                data => {
                    console.log('Success:', data);
                    // Update any UI component or trigger any event post success
                },
                error => {
                    console.error('Error:', error);
                },
                () => {
                    // Before upload actions
                    isProcessing = true; // Set processing flag before upload
                    showUserLoadingAnimation(); // Show loading animation
                    menu('menu-upload', 'hide', 250);
                },
                () => { }
            );
        }



        if (document.querySelector('#properties') || document.querySelector('#new-property')) {

            var uploadEndpoint;
            var propertyId;
            if (document.querySelector('#properties')) {
                uploadEndpoint = '/properties/uploadImage';
                propertyId = document.getElementById('propertyId').value
            }
            // Check if on the new-property page
            else if (document.querySelector('#new-property')) {
                uploadEndpoint = '/properties/temporaryImageUpload';
                propertyId = null;
            }



            setupFileUpload(
                'photoMenuItem',
                'photoInput',
                uploadEndpoint,
                data => {
                    console.log('Received data:', data); // Debugging: log the data received from the server
                    document.getElementById('housePhoto').src = data.imagePath; // Update the house photo on success
                    document.getElementById('imagePath').value = data.imagePath;
                },
                error => {
                    console.error('Error:', error);
                    document.getElementById('housePhoto').src = '/imgs/upload-house.png'; // Fallback image on error
                },
                () => {
                    menu('menu-upload', 'hide', 250); // Hide the menu before starting the upload
                    document.getElementById('addPropertySubmit').disabled = true; // Disable the submit button
                    document.getElementById('housePhoto').src = '/imgs/house-loading.gif'; // Show uploading indicator
                },
                () => {
                    document.getElementById('addPropertySubmit').disabled = false; // Re-enable the submit button after upload
                },
                { propertyId }
            );

            // For Camera Image Uploads
            setupFileUpload(
                'cameraMenuItem',
                'cameraInput',
                uploadEndpoint,
                data => {
                    document.getElementById('housePhoto').src = data.imagePath; // Update the house photo on success
                    document.getElementById('imagePath').value = data.imagePath;
                },
                error => {
                    console.error('Error:', error);
                    document.getElementById('housePhoto').src = '/imgs/upload-house.png'; // Fallback image on error
                },
                () => {
                    menu('menu-upload', 'hide', 250); // Hide the menu before starting the upload
                    document.getElementById('addPropertySubmit').disabled = true; // Disable the submit button
                    document.getElementById('housePhoto').src = '/imgs/house-loading.gif'; // Show uploading indicator
                },
                () => {
                    document.getElementById('addPropertySubmit').disabled = false; // Re-enable the submit button after upload
                },
                { propertyId }
            );
        }
        /******************************************************************************************************************
         * ****************************************************************************************************************
         * *********************************   TASKS DETAILS PAGE  STARTS HERE    **************************************************
         * 
         */

        if (document.querySelector('#task-details')) {

            var form = document.getElementById('taskForm');
            var updateButton = document.getElementById('updateTaskButton');
            var cancelButton = document.getElementById('cancelTaskButton');
            var cancelFlag = document.getElementById('canceled');

            updateButton.addEventListener('click', function () {
                cancelFlag.value = 'false';
                form.submit();
            });

            cancelButton.addEventListener('click', function () {
                if (confirm('Are you sure you want to cancel this task?')) {
                    cancelFlag.value = 'true';
                    form.submit();
                }
            });
        }

        /******************************************************************************************************************
         * ****************************************************************************************************************
         * *********************************   APT  DETAILS PAGE  STARTS HERE    **************************************************
         * 
         */

        if (document.querySelector('#appointment-details')) {
            var form = document.getElementById('appointmentForm');
            var updateButton = document.getElementById('updateAppointmentButton');

            updateButton.addEventListener('click', function () {
                form.submit();
            });
        }

        if (document.querySelector('#todo-list')) {
            const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');

            document.querySelectorAll('.priority-update').forEach(button => {
                button.addEventListener('click', (e) => {
                    e.preventDefault(); // Prevent the default anchor behavior

                    const taskId = button.getAttribute('data-task-id'); // Get task ID from button
                    const newPriority = button.getAttribute('data-priority'); // Get new priority from button

                    fetch(`/tasks/updatePriority/${taskId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': csrfToken,
                            // Include other headers as necessary
                        },
                        body: JSON.stringify({ priority: newPriority }),
                    })
                        .then(response => response.json())
                        .then(data => {
                            console.log('Priority updated successfully:', data);

                            const priorityDisplay = document.getElementById(`priorityDisplay${taskId}`);
                            if (priorityDisplay) {
                                priorityDisplay.textContent = `${newPriority} priority.`;
                                // Assuming you want to remove all possible priority classes before adding the new one
                                priorityDisplay.classList.remove('low-priority', 'medium-priority', 'high-priority');
                                priorityDisplay.classList.add(`${newPriority}-priority`);
                            }

                            let collapseElement = document.getElementById(`priorityCollapse${taskId}`);
                            let bsCollapse = new bootstrap.Collapse(collapseElement, {
                                toggle: false
                            });
                            bsCollapse.hide(); // Close the collapse
                        })
                        .catch((error) => {
                            console.error('Error updating priority:', error);
                            // Handle error
                        });
                });
            });

            var propertySelector = document.getElementById('propertySelector');
            var hiddenPropertyIdInput = document.getElementById('hiddenPropertyId');

            function updateTaskVisibility(selectedProperty) {
                var tasks = document.getElementsByClassName('task');
                Array.from(tasks).forEach(function (task) {
                    if (task.dataset.property === selectedProperty) {
                        task.classList.remove('d-none');
                    } else {
                        task.classList.add('d-none');
                    }
                });
            }

            function updateBannerVisibility(selectedProperty) {
                var banners = document.querySelectorAll('[data-property-id]');
                Array.from(banners).forEach(function (banner) {
                    if (banner.dataset.propertyId === selectedProperty) {
                        banner.classList.remove('d-none');
                    } else {
                        banner.classList.add('d-none');
                    }
                });
            }

            function initialize() {
                if (propertySelector) {

                    propertySelector.addEventListener('change', function () {
                        var selectedProperty = this.value;
                        hiddenPropertyIdInput.value = selectedProperty;
                        updateTaskVisibility(selectedProperty);
                        updateBannerVisibility(selectedProperty)

                        var appointmentLinks = document.querySelectorAll('[data-appointment-link]');
                        Array.from(appointmentLinks).forEach(function (link) {
                            var href = link.getAttribute('href').split('?')[0];
                            link.setAttribute('href', href + '?propertyId=' + selectedProperty);
                        });

                    });

                    window.addEventListener('pageshow', function (event) {
                        if (event.persisted) {
                            // The page was restored from the BFCache
                            console.log('Page loaded from BFCache');
                        }
                        // Trigger the change event on page show to ensure proper initialization
                        propertySelector.dispatchEvent(new Event('change'));
                    });

                } else if (hiddenPropertyIdInput) {
                    var singlePropertyId = hiddenPropertyIdInput.value;
                    updateTaskVisibility(singlePropertyId);
                    updateBannerVisibility(singlePropertyId);

                    var appointmentLinks = document.querySelectorAll('[data-appointment-link]');
                    Array.from(appointmentLinks).forEach(function (link) {
                        var href = link.getAttribute('href').split('?')[0];
                        link.setAttribute('href', href + '?propertyId=' + singlePropertyId);
                    });
                }
            }
            initialize();

            const form = document.getElementById('new-task-form');

            function submitFormData() {
                const formData = new FormData(form); // Create a FormData object from the form
                const formDataObject = {};
                formData.forEach((value, key) => { formDataObject[key] = value; });

                const url = '/tasks/create'; // The URL to which the form data will be sent

                return fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify(formDataObject),
                })
                    .then(response => {
                        if (!response.ok) throw new Error(`Server error: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {
                        console.log('Success:', data);
                        menu('menu-add-task', 'hide', 250); // Hide the menu after successful submission
                        displayNewTask(data.userTask, data.task); // Function to add the new task to the task list
                        form.reset();
                        return data;
                    });
            }

            form.addEventListener('submit', function (event) {
                event.preventDefault(); // Prevent the default form submission

                // Call the reusable function with the form element and the async operation
                performAsyncOperation(form, submitFormData);
            });

        }


        function displayNewTask(userTask, task) {
            const taskList = document.querySelector('#task-list-container');
            const taskElement = document.createElement('div');
            taskElement.className = 'task';
            taskElement.setAttribute('data-property', userTask.propertyId); // Assuming 'propertyId' is the correct property from your task object          
            // Create the row container
            const row = document.createElement('div');
            row.className = 'row align-items-center justify-content-between mb-3';
            taskElement.appendChild(row);

            // Create the column for the task title
            const colTitle = document.createElement('div');
            colTitle.className = 'col';
            row.appendChild(colTitle);

            // Create the link and title
            const taskLink = document.createElement('a');
            taskLink.className = 'text-decoration-none';
            taskLink.href = `/tasks/${userTask._id}`; // Assuming '_id' is the task identifier
            colTitle.appendChild(taskLink);

            const taskTitle = document.createElement('h4');
            taskTitle.className = 'mb-0';
            taskTitle.textContent = task.description; // Task description
            taskLink.appendChild(taskTitle);

            // Create the column for icons
            const colIcons = document.createElement('div');
            colIcons.className = 'col-auto';
            row.appendChild(colIcons);


            var priorityColor = userTask.priority === 'low' ? 'text-success' :
                userTask.priority === 'medium' ? 'text-warning' :
                    'text-danger'; // Default to 'text-danger' for high priority

            // Priority icon (assume logic to determine icon color based on task.priority)
            const priorityIcon = document.createElement('i');
            priorityIcon.className = `fa fa-exclamation ${priorityColor}`;
            colIcons.appendChild(priorityIcon);

            // Create the icon for estimated time or display a question mark if estimated time is not provided
            const timeIcon = document.createElement('i');
            if (task.taskType === 'custom' && !task.estimatedTime) {
                timeIcon.className = 'fa fa-circle-question text-secondary ms-2';
            } else if (task.estimatedTime) {
                // Determine the color based on the estimated time
                var timeColor = task.estimatedTime < 61 ? 'text-success' :
                    task.estimatedTime < 121 ? 'text-warning' :
                        task.estimatedTime < 181 ? 'text-orange' : 'text-danger';
                timeIcon.className = `fa fa-clock ${timeColor} ms-2`;
            }
            colIcons.appendChild(timeIcon);

            // Divider line
            const divider = document.createElement('div');
            divider.className = 'divider';
            divider.style.height = '1px';
            divider.style.backgroundColor = '#EAEAEA';
            taskElement.appendChild(divider);

            // Append the task element to the task list
            taskList.appendChild(taskElement);
        }


        if (document.querySelector('#admin-todo-list')) {
            const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
            const customerId = document.querySelector('meta[name="customerId"]').getAttribute('content');

            var propertySelector = document.getElementById('propertySelector');
            var hiddenPropertyIdInput = document.getElementById('hiddenPropertyId');

            function updateTaskVisibility(selectedProperty) {
                var tasks = document.getElementsByClassName('task');
                Array.from(tasks).forEach(function (task) {
                    if (task.dataset.property === selectedProperty) {
                        task.classList.remove('d-none');
                    } else {
                        task.classList.add('d-none');
                    }
                });
            }
            function updateBannerVisibility(selectedProperty) {
                var banners = document.querySelectorAll('[data-property-id]');
                Array.from(banners).forEach(function (banner) {
                    if (banner.dataset.propertyId === selectedProperty) {
                        banner.classList.remove('d-none');
                    } else {
                        banner.classList.add('d-none');
                    }
                });
            }
            function initialize() {
                if (propertySelector) {
                    propertySelector.addEventListener('change', function () {
                        var selectedProperty = this.value;
                        hiddenPropertyIdInput.value = selectedProperty;
                        updateTaskVisibility(selectedProperty);
                        updateBannerVisibility(selectedProperty);

                        const url = new URL(window.location);
                        url.searchParams.set('selectedProperty', selectedProperty);
                        window.history.pushState({}, '', url);

                        var appointmentLinks = document.querySelectorAll('[data-appointment-link]');
                        Array.from(appointmentLinks).forEach(function (link) {
                            var href = link.getAttribute('href').split('?')[0];
                            link.setAttribute('href', href + '?propertyId=' + selectedProperty);
                        });

                    });

                    window.addEventListener('pageshow', function (event) {
                        if (event.persisted) {
                            // The page was restored from the BFCache
                            console.log('Page loaded from BFCache');
                        }
                        // Trigger the change event on page show to ensure proper initialization
                        propertySelector.dispatchEvent(new Event('change'));
                    });
                }
            }
            initialize();

            const form = document.getElementById('new-task-form');

            form.addEventListener('submit', function (event) {
                event.preventDefault(); // Prevent the default form submission

                const formData = new FormData(form); // Create a FormData object from the form
                const formDataObject = {};
                formData.forEach((value, key) => { formDataObject[key] = value; });

                const url = `/tasks/create/${customerId}`; // The URL to which the form data will be sent

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify(formDataObject),
                })
                    .then(response => {
                        if (!response.ok) throw new Error(`Server error: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {
                        console.log('Success:', data);
                        menu('menu-add-task', 'hide', 250); // Hide the menu after successful submission
                        displayNewTask(data.userTask, data.task); // Function to add the new task to the task list
                        form.reset();
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            });

            function updateTaskStatus(button, taskId, newStatus) {
                fetch(`/admin/task/${taskId}/status`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify({ status: newStatus })
                })
                    .then(response => {
                        if (response.ok) {
                            location.reload();
                        } else {
                            console.error('Failed to update task status');
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }

            // Add event listeners for in-progress buttons
            document.querySelectorAll('.in-progress-btn').forEach(button => {
                button.addEventListener('click', function () {
                    let taskId = button.getAttribute('data-task-id');
                    updateTaskStatus(button, taskId, 'in-progress');
                });
            });

            // Add event listeners for done buttons
            document.querySelectorAll('.done-btn').forEach(button => {
                button.addEventListener('click', function () {
                    let taskId = button.getAttribute('data-task-id');
                    updateTaskStatus(button, taskId, 'completed');
                });
            });

            // Add event listeners for cancel buttons
            document.querySelectorAll('.cancel-btn').forEach(button => {
                button.addEventListener('click', function () {
                    let taskId = button.getAttribute('data-task-id');
                    updateTaskStatus(button, taskId, 'cancelled');
                });
            });

            /* START NEW TIMER FEATURES HERE */
            let timerInterval;
            let startTime;
            let elapsedTime = 0;

            const timerElement = document.getElementById('timer');
            const playButton = document.getElementById('playButton');
            const pauseButton = document.getElementById('pauseButton');
            const stopButton = document.getElementById('stopButton');
            const resetButton = document.getElementById('resetButton');
            const visitTypeSelect = document.getElementById('visitTypeSelect');
            const selectedVisitTypeContainer = document.getElementById('selectedVisitTypeContainer');
            const selectedVisitType = document.getElementById('selectedVisitType');

            // Initialize timer if activeTimer exists
            if (activeTimer && activeTimer.startTime) {
                startTime = new Date(activeTimer.startTime);
                elapsedTime = Date.now() - startTime.getTime(); // Correctly calculate elapsed time
                timerInterval = setInterval(updateTimer, 1000);
                playButton.classList.add('d-none');
                pauseButton.classList.remove('d-none');
                stopButton.classList.remove('d-none');
                resetButton.classList.remove('d-none');
            } else {
                playButton.classList.remove('d-none');
            }

            playButton.addEventListener('click', () => {
                startTime = Date.now() - elapsedTime;
                timerInterval = setInterval(updateTimer, 1000);
                playButton.classList.add('d-none');
                pauseButton.classList.remove('d-none');
                stopButton.classList.remove('d-none');
                resetButton.classList.remove('d-none');
                visitTypeSelect.classList.add('d-none');
                selectedVisitTypeContainer.classList.remove('d-none');
                selectedVisitType.textContent = visitTypeSelect.value;

                // Send start timestamp and visitType to server
                fetch('/admin/timer/start', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        propertyId: hiddenPropertyIdInput.value,
                        timestamp: new Date(startTime),
                        visitType: visitTypeSelect.value
                    }),
                });
            });

            pauseButton.addEventListener('click', () => {
                clearInterval(timerInterval);
                elapsedTime = Date.now() - startTime;
                playButton.classList.remove('d-none');
                pauseButton.classList.add('d-none');

                // Send pause timestamp to server
                fetch('/admin/timer/pause', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        propertyId: hiddenPropertyIdInput.value,
                        timestamp: new Date()
                    }),
                });
            });

            stopButton.addEventListener('click', () => {
                clearInterval(timerInterval);
                elapsedTime = 0;
                timerElement.textContent = '00:00:00';
                playButton.classList.remove('d-none');
                pauseButton.classList.add('d-none');
                stopButton.classList.add('d-none');
                resetButton.classList.add('d-none');
                selectedVisitTypeContainer.classList.add('d-none');
                visitTypeSelect.classList.remove('d-none');

                // Send stop timestamp to server
                fetch('/admin/timer/stop', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken,
                    },
                    body: JSON.stringify({
                        customerId: customerId,
                        propertyId: hiddenPropertyIdInput.value,
                        timestamp: new Date()
                    }),
                });
            });

            resetButton.addEventListener('click', () => {
                if (confirm('Are you sure you want to reset the timer?')) {
                    clearInterval(timerInterval);
                    elapsedTime = 0;
                    timerElement.textContent = '00:00:00';
                    playButton.classList.remove('d-none');
                    pauseButton.classList.add('d-none');
                    stopButton.classList.add('d-none');
                    resetButton.classList.add('d-none');
                    selectedVisitTypeContainer.classList.add('d-none');
                    visitTypeSelect.classList.remove('d-none');

                    // Send reset request to server
                    fetch('/admin/timer/reset', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': csrfToken,
                        },
                        body: JSON.stringify({
                            customerId: customerId,
                            propertyId: hiddenPropertyIdInput.value
                        }),
                    });
                }
            });

            function updateTimer() {
                const now = Date.now();
                const diff = now - startTime;
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                timerElement.textContent = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
            }

            function pad(num) {
                return num.toString().padStart(2, '0');
            }

        }

        /******************************************************************************************************************
         * ****************************************************************************************************************
         * *********************************   CALENDAR PAGE STARTS HERE    ***********************************************
         * 
         */

        if (document.querySelector('#calendar')) {

            // initialize calendar
            var calendarEl = document.getElementById('calendar');
            const employeeId = document.querySelector('meta[name="employeeId"]').getAttribute('content');
            const customerId = document.querySelector('meta[name="customerId"]').getAttribute('content');

            // Transform all appointments to the format required by FullCalendar
            var allEvents = appointments.map(appointment => {
                // Convert startTime to ISO string and extract the date and time
                let startDateTime = new Date(appointment.startTime);
                let endDateTime = new Date(appointment.endTime);
                let isCurrentUser = appointment.customerId.toString() === customerId;
                let isHighlighted = appointment._id.toString() === highlightAppointmentId;
                console.log(highlightAppointmentId);

                console.log('Client-side retrieved start time:', startDateTime);
                console.log('Client-side retrieved end time:', endDateTime);
                console.log(isHighlighted);

                return {
                    id: appointment._id,
                    title: `${appointment.visitType} - ${appointment.timeDescription}`,
                    start: startDateTime.toISOString(),
                    end: endDateTime.toISOString(),
                    backgroundColor: isCurrentUser ? (isHighlighted ? 'red' : 'orange') : 'blue',
                    // Add other event properties as needed
                };
            });

            var calendar = new Calendar(calendarEl, {
                plugins: [bootstrap5Plugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
                initialView: 'timeGridWeek',
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'timeGridWeek,timeGridDay, dayGridMonth' // user can switch between the two
                },
                themeSystem: 'bootstrap5',
                height: 'auto',
                dateClick: function (info) {
                    if (window.lastClickedDateCell) {
                        window.lastClickedDateCell.style.backgroundColor = '';  // Reset the background color
                    }
                    // You clicked on a date, now show the options
                    showOptions(info.dateStr);
                    info.dayEl.style.backgroundColor = 'rgb(157 141 255 / 42%)';
                    window.lastClickedDateCell = info.dayEl;
                },
                events: allEvents,
                eventClick: function (info) {
                    if (info.event.backgroundColor === 'blue' || info.event.backgroundColor === 'green') {
                        cancelEvent(info.event);
                    }
                },
                // Custom time range and hiding all-day slot
                slotMinTime: '07:00:00', // Time grid starts at 7 AM
                slotMaxTime: '20:00:00', // Time grid ends at 7 PM
                allDaySlot: false,
                editable: true,
                eventDrop: handleEventDropOrResize,
                eventResize: handleEventDropOrResize,
            });

            calendar.render();

            function handleEventDropOrResize(info) {
                let start = info.event.start.toISOString();
                let end = info.event.end.toISOString();
                let appointmentId = info.event.id;
                // Fetch CSRF token as before
                let _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

                fetch('/admin/rescheduleAppointment', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ appointmentId, start, end, _csrf })
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }
                    return response.json();
                }).then(data => {
                    console.log('Appointment rescheduled successfully:', data);
                }).catch(error => {
                    console.error('Error during appointment reschedule:', error);
                    info.revert(); // Reverts the event to its original position in case of error
                });
            }


            const visitTypes = {
                iv: 'Initial Visit',
                rm: 'Routine Monthly',
                bd: 'Banked Double',
                em: 'Emergency',
                ex: 'Extra',
            };

            const visitDurations = {
                "Initial Visit": 1,
                "Routine Monthly": 3,
                "Banked Double": 6,
                "Emergency": 2,
                "Extra": 6,
            };

            const hourDropdown = document.querySelector('#hour-dropdown');
            const minuteDropdown = document.querySelector('#minute-dropdown');
            const visitTypeDropdown = document.querySelector('#visit-type-dropdown');

            // Create an event listener function for setting an appointment
            const setAppointmentListener = function () {
                const hour = hourDropdown.value;
                const minute = minuteDropdown.value;
                const visitType = visitTypeDropdown.value;
                const time = `${hour}:${minute}`;

                var _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

                // Calculate the startTime and endTime
                const startTime = new Date(date);
                startTime.setHours(hour, minute, 0, 0);
                const endTime = new Date(startTime);
                const duration = visitDurations[visitTypes[visitType]];
                endTime.setHours(endTime.getHours() + duration);

                // Prepare the data to be sent
                const data = {
                    date: new Date(date),
                    time: time,
                    startTime: startTime,
                    endTime: endTime,
                    customerId: customerId,
                    employeeId: employeeId,
                    visitType: visitTypes[visitType], // Use the selected visit type's description
                    _csrf: _csrf
                };

                // Send the data to the server
                fetch('/admin/calendar/setAppointment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Error: ' + response.statusText);
                    }
                }).then(data => {
                    // This code runs once the server responds successfully
                    console.log('Success:', data);

                    // Append the newly created appointment to the existing events list
                    var event = {
                        id: data._id, // assuming the server response contains the appointment id
                        title: `${data.visitType} - ${data.time}`,
                        start: data.startTime, // Use the startTime returned from the server
                        end: data.endTime, // Use the endTime returned from the server
                        // add any other properties that your event objects require
                    };
                    calendar.addEvent(event);

                    menu('date-action-sheet', 'hide', 250);
                }).catch((error) => {
                    // This code runs if there was an error
                    console.error('Error:', error);
                });
            };

            // Add the new event listener to the confirm button in your action sheet
            const confirmBtn = document.querySelector('#confirm-set-appointment-btn'); // Make sure this button is defined in your PUG
            confirmBtn.addEventListener('click', setAppointmentListener);

            var date;

            function showOptions(selectedDate) {
                date = selectedDate;

                // Get the element which has the correctly formatted date in an aria-label attribute
                let dateElement = document.querySelector(`td[data-date="${date}"] a`);
                if (dateElement) {
                    // If the element was found, retrieve the formatted date from the aria-label attribute
                    let formattedDate = dateElement.getAttribute('aria-label');
                    // Update the selected date text
                    document.querySelector('#selected-date').textContent = formattedDate;
                } else {
                    // If the element was not found, fall back to using the unformatted date
                    document.querySelector('#selected-date').textContent = date;
                }

                document.querySelector('#close-action-sheet').addEventListener('click', function () {
                    menu('date-action-sheet', 'hide', 250);
                });

                // Open the action sheet
                menu('date-action-sheet', 'show', 250);
            }


            function cancelEvent(event) {
                // Open the cancel action sheet
                menu('cancel-action-sheet', 'show', 250);
                let date = event.start;
                let dateString = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });

                // Display the event title (or any other information you'd like) in the h1
                document.querySelector('#selected-event').innerText = `${dateString}\n${event.title}`;
                console.log(event.id);


                // Get the CSRF token
                var _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

                // Add event listener to the "Confirm Cancel" button
                document.querySelector('#confirm-cancel-btn').addEventListener('click', function () {
                    // Send the fetch request to cancel the event here
                    fetch('/calendar/cancelAppointment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ appointmentId: event.id, _csrf: _csrf })
                    }).then(response => {
                        // Handle the response here. You could, for example, remove the event from the calendar.
                        event.remove();
                        menu('cancel-action-sheet', 'hide', 250);
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                });

                // Add event listener to the "Deny" button
                document.querySelector('#deny-cancel-btn').addEventListener('click', function () {
                    // Just close the cancel action sheet
                    menu('cancel-action-sheet', 'hide', 250);
                });
            }

        }

        /*************************************************************************************
        * ***********************************************************************************
        * *********************************   BILLING PAGE BUTTONS  *************************
        */

        if (document.querySelector('#billing')) {



            const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');

            const pauseSubButton = document.getElementById('pauseSub');
            const resumeSubButton = document.getElementById('resumeSub');
            const cancelSubButton = document.getElementById('cancelSub');
            const updatePaymentButton = document.getElementById('updatePayment');

            if (pauseSubButton) {
                pauseSubButton.addEventListener('click', () => {
                    updateSubscription('pause', csrfToken);
                });
            }

            if (resumeSubButton) {
                resumeSubButton.addEventListener('click', () => {
                    updateSubscription('resume', csrfToken);
                });
            }

            if (cancelSubButton) {
                cancelSubButton.addEventListener('click', () => {
                    updateSubscription('cancel', csrfToken);
                });
            }

            if (updatePaymentButton) {
                updatePaymentButton.addEventListener('click', () => {
                    // Redirect to the payment update page or handle payment update logic
                });
            }


            function updateSubscription(action, csrfToken) {
                fetch(`/subscription/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken
                    },
                    body: JSON.stringify({ /* Additional data if needed */ })
                })
                    .then(response => {
                        if (response.ok) {
                            window.location.href = '/todo-list'; // Reload the page on success
                        } else {
                            alert('There was an error processing your request.');
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert('An error occurred. Please try again.');
                    });
            }
        }

        /*************************************************************************************
        * ***********************************************************************************
        * *********************************   ADD/UPDATE PAYMENT  ***************************
        *
        */

        if (document.querySelector('#updatePayment')) {
            const _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

            // Fetch the Stripe publishable key from your endpoint
            const { publishableKey } = await fetch('/stripeConfig').then(r => r.json());
            const stripe = Stripe(publishableKey);
            const elements = stripe.elements();
            const paymentElement = elements.create('payment');
            paymentElement.mount('#payment-element');

            const form = document.getElementById('payment-form');

            form.addEventListener('submit', async (e) => {
                e.preventDefault();
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: paymentElement,
                });

                if (error) {
                    document.getElementById('card-errors').textContent = error.message;
                } else {
                    // Send paymentMethod.id to your server
                    fetch('/subscription/update', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': _csrf
                        },
                        body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
                    }).then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                // Redirect to the /chat page on success
                                window.location.href = '/todo-list';
                            } else {
                                // Handle errors, display messages as needed
                                alert(data.message || 'Failed to add payment method.');
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            alert('An error occurred. Please try again.');
                        });
                }
            });
        }


        /*************************************************************************************
          * ***********************************************************************************
          * *********************************   STRIPE INTEGRATION  ***************************
          *
          */

        if (document.querySelector('#checkout-page')) {


            const _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');
            const radioButtons = document.querySelectorAll("input[type='radio']");


            const { publishableKey } = await fetch('/stripeConfig').then((r) => r.json());

            createSubscription('monthly');
            console.log(window.userEmail)

            async function createSubscription(plan) {
                const response = await fetch('/create-subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-csrf-token': _csrf // assuming you are using CSRF tokens for security
                    },
                    body: JSON.stringify({ plan })
                });
                const data = await response.json();

                if (data.error) {
                    stripeMessage(data.error.message);
                } else {
                    // Initialize Stripe elements here with the new clientSecret
                    const stripe = Stripe(publishableKey);
                    const elements = stripe.elements({ clientSecret: data.clientSecret });
                    const paymentElement = elements.create('payment');
                    paymentElement.mount('#payment-element');
                    const linkAuthenticationElement = elements.create("linkAuthentication", {
                        defaultValues: {
                            email: window.userEmail,
                        }
                    });
                    linkAuthenticationElement.mount("#link-authentication-element")

                    // When the form is submitted...
                    const form = document.getElementById('payment-form');
                    let submitted = false;


                    form.addEventListener('submit', async (e) => {
                        e.preventDefault();

                        // Disable double submission of the form
                        if (submitted) { return; }
                        submitted = true;
                        form.querySelector('button').disabled = true;

                        const { error: stripeError } = await stripe.confirmPayment({
                            elements,
                            confirmParams: {
                                return_url: `${window.location.origin}/return`,
                            }
                        });

                        if (stripeError) {
                            stripeMessage(stripeError.message);

                            // reenable the form.
                            submitted = false;
                            form.querySelector('button').disabled = false;
                            return;
                        }
                    });
                }
            }

            Array.from(radioButtons).forEach(function (radioButton) {
                radioButton.addEventListener("change", function (e) {
                    handleClick(e);
                    createSubscription(e.target.value); // Create a new payment intent with the selected plan
                });
            });

            function handleClick(e) {
                const monthlyCard = document.getElementById("monthlyCard");
                const annuallyCard = document.getElementById("annuallyCard");

                monthlyCard.classList.toggle("border-danger", e.target.value !== "annually");
                annuallyCard.classList.toggle("border-danger", e.target.value === "annually");
            }

            const stripeMessage = (message) => {
                if (typeof message === 'undefined' || message === null) {
                    console.error('Stripe message is undefined or null');
                    return; // Exit the function if message is not valid
                }

                const messagesDiv = document.querySelector('#error-message');
                messagesDiv.style.display = 'block';
                const messageWithLinks = addDashboardLinks(message);
                messagesDiv.innerHTML += `${messageWithLinks}<br>`;
                console.log(`Debug: ${message}`);
            };

            // Adds links for known Stripe objects to the Stripe dashboard.
            const addDashboardLinks = (message) => {
                const piDashboardBase = 'https://dashboard.stripe.com/test/payments';
                return message.replace(
                    /(pi_(\S*)\b)/g,
                    `<a href="${piDashboardBase}/$1" target="_blank">$1</a>`
                );
            };
        }



        /*************************************************************************************
         * ***********************************************************************************
         * *********************************   STRIPE PAY SUCCEEDED  *************************
         */
        if (document.querySelector('#pay-completed')) {

            const { publishableKey } = await fetch('/stripeConfig').then((r) => r.json());
            const stripe = Stripe(publishableKey);

            const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                const statusHeader = document.getElementById('payment-status-header');
                const statusMessage = document.getElementById('payment-status-message');
                const failedPaymentLink = document.getElementById('failed-payment-link');

                switch (paymentIntent.status) {
                    case 'succeeded':
                        statusHeader.innerText = 'Payment Successful';
                        statusMessage.innerText = 'Thank you! Your payment has been received.';
                        break;
                    case 'processing':
                        statusHeader.innerText = 'Payment Processing';
                        statusMessage.innerText = "We're processing your payment. We'll update you when payment is received.";
                        break;
                    case 'requires_payment_method':
                        statusHeader.innerText = 'Payment Failed';
                        statusMessage.innerText = 'Unfortunately, the payment did not go through. Please try another payment method.';
                        failedPaymentLink.classList.remove('d-none'); // Show the link
                        break;
                    default:
                        statusHeader.innerText = 'Payment Status';
                        statusMessage.innerText = 'Something went wrong. Please check your payment details.';
                        failedPaymentLink.classList.remove('d-none'); // Show the link
                        break;
                }
            });

            document.getElementById('schedule-recurring-visit').addEventListener('click', function () {
                window.location.href = '/appointments/rm';
            });
        }

        /*************************************************************************************
         * ***********************************************************************************
         * *********************************   STRIPE AD HOC PAYMENT  ************************
         * 
         */
        if (document.getElementById('adhoc')) {

            const customerId = window.location.pathname.split('/').pop();
            const form = document.getElementById('charge-form');
            const _csrf = form.querySelector('input[name="_csrf"]').value;
            const submitChargeBtn = document.getElementById('submit-charge-btn');

            form.addEventListener('submit', function (e) {
                e.preventDefault(); // Prevent default form submission

                submitChargeBtn.disabled = true;

                const formData = new FormData(form);
                fetch('/admin/adhoc-charge', {
                    method: 'POST',
                    headers: {
                        'x-csrf-token': _csrf, // Include CSRF token as a header
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams(formData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            showSuccessMessage(data);
                            updateAdHocPaymentsList(); // Implement this function to update the list
                            clearInputFields();
                            submitChargeBtn.disabled = false;
                        } else {
                            // Handle failure
                            submitChargeBtn.disabled = false;
                            console.error('Payment failed', data);
                        }
                    })
                    .catch(error => {
                        submitChargeBtn.disabled = false;
                        console.error('Error:', error);
                    });
            });

            function clearInputFields() {
                const chargeType = document.getElementById('chargeType').value;
                document.getElementById('description').value = '';

                if (chargeType === 'labor') {
                    document.getElementById('billableHours').value = '';
                    document.getElementById('hourlyRate').value = '50'; // Reset to default if needed
                    document.getElementById('subtotal').value = '';
                    document.getElementById('salesTax').value = '';
                    document.getElementById('total').value = '';
                } else if (chargeType === 'materials') {
                    document.getElementById('receiptTotal').value = '';
                    document.getElementById('includesSalesTax').checked = false;
                    document.getElementById('store').value = '';
                    document.getElementById('location').value = '';
                    document.getElementById('zip_code').value = '';
                    document.getElementById('receiptDate').value = '';
                    document.getElementById('taxes_paid').value = '';

                    document.getElementById('store').style.display = 'none';
                    document.querySelector('label[for="store"]').style.display = 'none';

                    document.getElementById('location').style.display = 'none';
                    document.querySelector('label[for="location"]').style.display = 'none';

                    document.getElementById('zip_code').style.display = 'none';
                    document.querySelector('label[for="zip_code"]').style.display = 'none';

                    document.getElementById('receiptDate').style.display = 'none';
                    document.querySelector('label[for="receiptDate"]').style.display = 'none';

                    document.getElementById('taxes_paid').style.display = 'none';
                    document.querySelector('label[for="taxes_paid"]').style.display = 'none';

                    // Clear and hide the receipt image
                    document.getElementById('receiptImage').src = '';
                    document.getElementById('receiptImage').style.display = 'none';
                    document.getElementById('receiptLabel').style.display = 'none';
                }
            }


            function showSuccessMessage(data) {
                const successMessage = document.getElementById('payment-success');
                const messageText = document.getElementById('success-message');
                messageText.textContent = `Success! Payment processed for $${data.paymentIntent.amount / 100}.`;
                successMessage.classList.remove('d-none');
            }

            function updateAdHocPaymentsList() {
                // Assume you have an endpoint that returns the updated list of payments
                fetch(`/admin/getAdhocPayments/${customerId}`)
                    .then(response => response.json())
                    .then(payments => {
                        const paymentsTableBody = document.querySelector('.table-responsive table tbody');
                        paymentsTableBody.innerHTML = ''; // Clear existing table body
                        payments.forEach(payment => {
                            const row = document.createElement('tr'); // Create a table row for each payment

                            // Construct the table data for each payment detail
                            row.innerHTML = `
                    <td>${payment.invoiceNum}</td>
                    <td>$${payment.totalAmount.toFixed(2)}</td>
                    <td>${payment.description}</td>
                    <td>${payment.status}</td>
                    <td>${new Date(payment.date).toLocaleDateString()}</td>
                    <td><a href="${payment.receiptUrl}" target="_blank">View Receipt</a></td>
                  `;
                            paymentsTableBody.appendChild(row); // Append the row to the table body
                        });
                    })
                    .catch(error => console.error('Error updating payments list:', error));
            }

            const chargeTypeSelect = document.getElementById('chargeType');
            const laborFields = document.getElementById('laborFields');
            const materialFields = document.getElementById('materialFields');
            const uploadReceiptBtn = document.getElementById('upload-receipt-btn');
            const descriptionInput = document.getElementById('description');
            const uploadProgress = document.getElementById('upload-progress');

            function updateFieldVisibility() {
                if (chargeTypeSelect.value === 'materials') {
                    uploadReceiptBtn.style.display = 'block';
                    descriptionInput.required = false;
                    laborFields.style.display = 'none';
                    materialFields.style.display = 'block';
                } else {
                    uploadReceiptBtn.style.display = 'none';
                    descriptionInput.required = true;
                    laborFields.style.display = 'block';
                    materialFields.style.display = 'none';
                }
            }

            chargeTypeSelect.addEventListener('change', updateFieldVisibility);

            // Initial call to set the correct visibility on page load
            updateFieldVisibility();

            const billableHoursInput = document.getElementById('billableHours');
            const hourlyRateInput = document.getElementById('hourlyRate');
            const subtotalInput = document.getElementById('subtotal');
            const salesTaxInput = document.getElementById('salesTax');
            const totalInput = document.getElementById('total');

            function calculateSubtotal() {
                const billableHours = parseFloat(billableHoursInput.value) || 0;
                const hourlyRate = parseFloat(hourlyRateInput.value) || 0;
                const subtotal = billableHours * hourlyRate;
                subtotalInput.value = subtotal.toFixed(2);
                return subtotal;
            }

            function calculateSalesTax(subtotal) {
                const taxRate = 0.07; // Assuming an 8% sales tax rate
                const salesTax = subtotal * taxRate;
                salesTaxInput.value = salesTax.toFixed(2);
                return salesTax;
            }

            function calculateTotal(subtotal, salesTax) {
                const total = subtotal + salesTax;
                totalInput.value = total.toFixed(2);
                return total;
            }

            billableHoursInput.addEventListener('input', () => {
                const subtotal = calculateSubtotal();
                const salesTax = calculateSalesTax(subtotal);
                calculateTotal(subtotal, salesTax);
            });

            hourlyRateInput.addEventListener('input', () => {
                const subtotal = calculateSubtotal();
                const salesTax = calculateSalesTax(subtotal);
                calculateTotal(subtotal, salesTax);
            });

            const onSuccess = (data) => {
                console.log('Received data:', data); // Debugging: log the data received from the server
                // Update hidden input fields and display image
                document.getElementById('imagePath').value = data.imagePath;
                document.getElementById('description').value = data.visionResponse.summary;
                document.getElementById('receiptTotal').value = data.visionResponse.total_paid;
                document.getElementById('store').value = data.visionResponse.store;
                document.getElementById('location').value = data.visionResponse.location;
                document.getElementById('zip_code').value = data.visionResponse.zip_code;
                document.getElementById('receiptDate').value = data.visionResponse.date;
                document.getElementById('taxes_paid').value = data.visionResponse.taxes_paid;
                document.getElementById('receiptImage').src = data.imagePath;
                document.getElementById('receiptImage').style.display = 'block';
                document.getElementById('receiptLabel').style.display = 'block';
                uploadProgress.style.display = 'none'; // Hide the progress indicator

                document.getElementById('store').style.display = 'block';
                document.querySelector('label[for="store"]').style.display = 'block';

                document.getElementById('location').style.display = 'block';
                document.querySelector('label[for="location"]').style.display = 'block';

                document.getElementById('zip_code').style.display = 'block';
                document.querySelector('label[for="zip_code"]').style.display = 'block';

                document.getElementById('receiptDate').style.display = 'block';
                document.querySelector('label[for="receiptDate"]').style.display = 'block';

                document.getElementById('taxes_paid').style.display = 'block';
                document.querySelector('label[for="taxes_paid"]').style.display = 'block';
            };

            const onError = (error) => {
                console.error('Error:', error);
                document.getElementById('receiptImage').src = '/imgs/upload-house.png'; // Fallback image on error
                uploadProgress.style.display = 'none'; // Hide the progress indicator
            };

            setupFileUpload(
                'photoMenuItem',
                'photoInput',
                '/admin/adhoc-charge/upload-receipt',
                onSuccess,
                onError,
                () => {
                    menu('menu-upload', 'hide', 250); // Hide the menu before starting the upload
                    submitChargeBtn.disabled = true; // Disable the submit button
                    uploadProgress.style.display = 'block';
                    document.getElementById('receiptImage').src = '/imgs/house-loading.gif'; // Show uploading indicator
                    document.getElementById('receiptImage').style.display = 'block';
                },
                () => {
                    submitChargeBtn.disabled = false; // Re-enable the submit button after upload
                    uploadProgress.style.display = 'none';
                }
            );

            setupFileUpload(
                'cameraMenuItem',
                'cameraInput',
                '/admin/adhoc-charge/upload-receipt',
                onSuccess,
                onError,
                () => {
                    menu('menu-upload', 'hide', 250); // Hide the menu before starting the upload
                    submitChargeBtn.disabled = true; // Disable the submit button
                    uploadProgress.style.display = 'block';
                    document.getElementById('receiptImage').src = '/imgs/house-loading.gif'; // Show uploading indicator
                    document.getElementById('receiptImage').style.display = 'block';
                },
                () => {
                    submitChargeBtn.disabled = false; // Re-enable the submit button after upload
                    uploadProgress.style.display = 'none';
                }
            );

        };

        /********************************************************
         * 
         *              DASHBOARD GENERAL RECEIPT UPLOAD SECTION
         */
        if (document.querySelector('#dashboard')) {
            const uploadProgressContent = document.getElementById('upload-progress-content');
            const menuUploadContent = document.getElementById('menu-upload-content');
            const receiptDetailsForm = document.getElementById('receipt-details-form');
            const receiptResultMenu = document.getElementById('menu-receipt-result');

            function showLoader2() {
                menuUploadContent.style.display = 'none';
                uploadProgressContent.style.display = 'block';
              }
            
              function hideLoader2() {
                uploadProgressContent.style.display = 'none';
                menuUploadContent.style.display = 'block';
              }

            const onSuccess = (data) => {
                console.log('Received data:', data);
                document.getElementById('receipt-store').value = data.visionResponse.store;
                document.getElementById('receipt-location').value = data.visionResponse.location;
                document.getElementById('receipt-zip_code').value = data.visionResponse.zip_code;
                document.getElementById('receipt-date').value = data.visionResponse.date;
                document.getElementById('receipt-summary').value = data.visionResponse.summary;
                document.getElementById('receipt-total_paid').value = data.visionResponse.total_paid;
                document.getElementById('receipt-taxes_paid').value = data.visionResponse.taxes_paid;
            
                hideLoader2();
                menu('menu-receipt-result', 'show', 250);
              };

            const onError = (error) => {
                console.error('Error:', error);
            };


            setupFileUpload(
                'photoMenuItem',
                'photoInput',
                '/admin/adhoc-charge/upload-receipt',
                onSuccess,
                onError,
                () => {
                    showLoader2();
                },
                () => {
                    menu('menu-upload', 'hide', 250);
                    hideLoader2();
                }
            );

            setupFileUpload(
                'cameraMenuItem',
                'cameraInput',
                '/admin/adhoc-charge/upload-receipt',
                onSuccess,
                onError,
                () => {
                    showLoader2();
                },
                () => {
                    menu('menu-upload', 'hide', 250);
                    hideLoader2();
                }
            );

            document.querySelectorAll('.category-btn').forEach(button => {
                button.addEventListener('click', () => {
                    const category = button.getAttribute('data-category');
                    fetch('/dashboard/save-receipt', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': document.querySelector('input[name="_csrf"]').value
                        },
                        body: JSON.stringify({
                            category,
                            receiptData: {
                                store: document.getElementById('receipt-store').value,
                                location: document.getElementById('receipt-location').value,
                                zip_code: document.getElementById('receipt-zip_code').value,
                                date: document.getElementById('receipt-date').value,
                                summary: document.getElementById('receipt-summary').value,
                                total_paid: document.getElementById('receipt-total_paid').value,
                                taxes_paid: document.getElementById('receipt-taxes_paid').value
                            }
                        })
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                alert('Receipt saved successfully');
                                menu('menu-receipt-result', 'hide', 250);
                            } else {
                                console.error('Failed to save receipt', data);
                            }
                        })
                        .catch(error => console.error('Error:', error));
                });
            });

        }



        /**********
         * MORPH EFFECT
         */
        if (document.querySelector('#home')) {
            const elts = {
                text1: document.getElementById("text1"),
                text2: document.getElementById("text2")
            };

            const texts = [
                "repair",
                "upgrades",
                "maintenance",
                "seasonal chores",
                "updates",
                "fixes",
                "routine tasks",
            ];

            const morphTime = 1;
            const cooldownTime = 2;

            let textIndex = texts.length - 1;
            let time = new Date();
            let morph = 0;
            let cooldown = cooldownTime;

            elts.text1.textContent = texts[textIndex % texts.length];
            elts.text2.textContent = texts[(textIndex + 1) % texts.length];

            function doMorph() {
                morph -= cooldown;
                cooldown = 0;

                let fraction = morph / morphTime;

                if (fraction > 1) {
                    cooldown = cooldownTime;
                    fraction = 1;
                }

                setMorph(fraction);
            }

            function setMorph(fraction) {
                elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
                elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

                fraction = 1 - fraction;
                elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
                elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

                elts.text1.textContent = texts[textIndex % texts.length];
                elts.text2.textContent = texts[(textIndex + 1) % texts.length];
            }

            function doCooldown() {
                morph = 0;

                elts.text2.style.filter = "";
                elts.text2.style.opacity = "100%";

                elts.text1.style.filter = "";
                elts.text1.style.opacity = "0%";
            }

            function animate() {
                requestAnimationFrame(animate);

                let newTime = new Date();
                let shouldIncrementIndex = cooldown > 0;
                let dt = (newTime - time) / 1000;
                time = newTime;

                cooldown -= dt;

                if (cooldown <= 0) {
                    if (shouldIncrementIndex) {
                        textIndex++;
                    }

                    doMorph();
                } else {
                    doCooldown();
                }
            }

            animate();

            gsap.registerPlugin(ScrollTrigger);

            const basePath = "../imgs/checks/";
            const checkmarkImages = [
                'checkmark1.png',
                'checkmark2.png',
                'checkmark3.png',
                'checkmark4.png'
            ].map(file => basePath + file); // Full paths to checkmark images

            const strikethroughImages = [
                'strikethrough1.png',
                'strikethrough2.png',
                'strikethrough3.png',
                'strikethrough4.png'
            ].map(file => basePath + file); // Full paths to strikethrough images

            function getRandomImage(imagesArray) {
                return imagesArray[Math.floor(Math.random() * imagesArray.length)];
            }

            gsap.utils.toArray('.checklist-item').forEach((item, index) => {
                let checkmarkSpan = item.querySelector('.checkmark');
                let strikethroughSpan = item.querySelector('.strikethrough');

                ScrollTrigger.create({
                    trigger: item,
                    start: `${index * 30}px center`,
                    onEnter: () => {
                        item.classList.add('checked');
                        // Set background images immediately so sizes can be calculated
                        checkmarkSpan.style.backgroundImage = `url("${getRandomImage(checkmarkImages)}")`;
                        strikethroughSpan.style.backgroundImage = `url("${getRandomImage(strikethroughImages)}")`;
                        // Now animate the widths
                        gsap.fromTo(checkmarkSpan, { width: '0%' }, { width: '50px', duration: 0.5, delay: index * 0.12 }); // Set the target width according to your design
                        gsap.fromTo(strikethroughSpan, { width: '0%' }, { width: '120px', duration: 0.5, delay: index * 0.12 }); // Strikethrough spans the full width
                    }
                });
            });

        }



        //Validator
        var inputField = document.querySelectorAll('input');
        if (inputField.length) {
            var mailValidator = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
            var phoneValidator = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/;
            var nameValidator = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
            var passwordValidator = /[A-Za-z]{2}[A-Za-z]*[ ]?[A-Za-z]*/;
            var numberValidator = /^(0|[1-9]\d*)$/;
            var linkValidator = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
            var textValidator = /[A-Za-z]{2}[A-Za-z]*[ ]?[A-Za-z]*/;

            function valid(el) {
                el.parentElement.querySelectorAll('.valid')[0].classList.remove('disabled');
                el.parentElement.querySelectorAll('.invalid')[0].classList.add('disabled');
            }
            function invalid(el) {
                el.parentElement.querySelectorAll('.valid')[0].classList.add('disabled');
                el.parentElement.querySelectorAll('.invalid')[0].classList.remove('disabled');
            }
            function unfilled(el) {
                el.parentElement.querySelectorAll('em')[0].classList.remove('disabled');
                el.parentElement.querySelectorAll('.valid')[0].classList.add('disabled');
                el.parentElement.querySelectorAll('.invalid')[0].classList.add('disabled');
            }

            var regularField = document.querySelectorAll('.input-style input:not([type="date"])')
            regularField.forEach(el => el.addEventListener('keyup', e => {
                if (!el.value == "") {
                    el.parentElement.classList.add('input-style-active');
                    el.parentElement.querySelector('em').classList.add('disabled');
                } else {
                    el.parentElement.querySelectorAll('.valid')[0].classList.add('disabled');
                    el.parentElement.querySelectorAll('.invalid')[0].classList.add('disabled');
                    el.parentElement.classList.remove('input-style-active');
                    el.parentElement.querySelector('em').classList.remove('disabled');
                }
            }));

            var regularTextarea = document.querySelectorAll('.input-style textarea')
            regularTextarea.forEach(el => el.addEventListener('keyup', e => {
                if (!el.value == "") {
                    el.parentElement.classList.add('input-style-active');
                    el.parentElement.querySelector('em').classList.add('disabled');
                } else {
                    el.parentElement.classList.remove('input-style-active');
                    el.parentElement.querySelector('em').classList.remove('disabled');
                }
            }));

            var selectField = document.querySelectorAll('.input-style select')
            selectField.forEach(el => el.addEventListener('change', e => {
                if (el.value !== "default") {
                    el.parentElement.classList.add('input-style-active');
                    el.parentElement.querySelectorAll('.valid')[0].classList.remove('disabled');
                    el.parentElement.querySelectorAll('.invalid, em, span')[0].classList.add('disabled');
                }
                if (el.value == "default") {
                    el.parentElement.querySelectorAll('span, .valid, em')[0].classList.add('disabled');
                    el.parentElement.querySelectorAll('.invalid')[0].classList.remove('disabled');
                    el.parentElement.classList.add('input-style-active');
                }

            }));

            var dateField = document.querySelectorAll('.input-style input[type="date"]')
            dateField.forEach(el => el.addEventListener('change', e => {
                el.parentElement.classList.add('input-style-active');
                el.parentElement.querySelectorAll('.valid')[0].classList.remove('disabled');
                el.parentElement.querySelectorAll('.invalid')[0].classList.add('disabled');
            }));

            var validateField = document.querySelectorAll('.validate-field input, .validator-field textarea');
            if (validateField.length) {
                validateField.forEach(el => el.addEventListener('keyup', e => {
                    var getAttribute = el.getAttribute('type');
                    switch (getAttribute) {
                        case 'name': nameValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'number': numberValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'email': mailValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'text': textValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'url': linkValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'tel': phoneValidator.test(el.value) ? valid(el) : invalid(el); break;
                        case 'password': passwordValidator.test(el.value) ? valid(el) : invalid(el); break;
                    }

                    if (el.value === "") { unfilled(el); }
                }));
            }

        }


        /**********************************************************************************************
         * *******************************************************************************************
         * *********************************   EMPLOYEE CHAT PAGE  ************************************
         * 
         */



        //const _csrf = document.querySelector('meta[name="_csrf"]').getAttribute('content');

        document.querySelectorAll('[data-trigger-fetch]').forEach(el => {
            el.addEventListener('click', async event => {
                var fetchTarget = el.getAttribute('data-trigger-fetch');
                var checkbox = document.getElementById(fetchTarget);
                var newValue = !checkbox.checked; // toggles the value
                console.log('New value:', newValue);
                console.log('Fetch target:', fetchTarget);
                console.log('Checkbox:', checkbox);
                checkbox.checked = newValue;

                const userId = window.location.pathname.split('/').pop();



                try {
                    const response = await fetch(`/admin/toggler/${userId}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-csrf-token': _csrf,
                        },
                        body: JSON.stringify({ setting: fetchTarget, value: newValue })
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }

                    const jsonResponse = await response.json();
                    console.log('Update successful:', jsonResponse);
                } catch (error) {
                    console.error('Fetch error:', error);
                }
            });
        });




        //Image Sliders
        var splide = document.getElementsByClassName('splide');
        if (splide.length) {
            var singleSlider = document.querySelectorAll('.single-slider');
            if (singleSlider.length) {
                singleSlider.forEach(function (e) {
                    var single = new Splide('#' + e.id, {
                        type: 'loop',
                        autoplay: true,
                        interval: 4000,
                        perPage: 1,
                    }).mount();
                    var sliderNext = document.querySelectorAll('.slider-next');
                    var sliderPrev = document.querySelectorAll('.slider-prev');
                    sliderNext.forEach(el => el.addEventListener('click', el => { single.go('>'); }));
                    sliderPrev.forEach(el => el.addEventListener('click', el => { single.go('<'); }));
                });
            }

            var doubleSlider = document.querySelectorAll('.double-slider');
            if (doubleSlider.length) {
                doubleSlider.forEach(function (e) {
                    var double = new Splide('#' + e.id, {
                        type: 'loop',
                        autoplay: true,
                        interval: 4000,
                        arrows: false,
                        perPage: 2,
                    }).mount();
                });
            }

            var trippleSlider = document.querySelectorAll('.tripple-slider');
            if (trippleSlider.length) {
                trippleSlider.forEach(function (e) {
                    var tripple = new Splide('#' + e.id, {
                        type: 'loop',
                        autoplay: true,
                        padding: {
                            left: '0px',
                            right: '80px',
                        },
                        interval: 4000,
                        arrows: false,
                        perPage: 2,
                        perMove: 1,
                    }).mount();
                });
            }
        }

        //Don't jump on Empty Links
        const emptyHref = document.querySelectorAll('a[href="#"]')
        emptyHref.forEach(el => el.addEventListener('click', e => {
            e.preventDefault();
            return false;
        }));

        //Map Page
        var fullMap = document.querySelectorAll('.map-full');
        if (fullMap.length) {
            var mapActivator = document.querySelectorAll('.show-map');
            var mapDisabler = document.querySelectorAll('.hide-map');
            mapActivator[0].addEventListener('click', function (e) {
                document.getElementsByClassName('card-overlay')[0].classList.add('disabled');
                document.getElementsByClassName('card-center')[0].classList.add('disabled');
                document.getElementsByClassName('hide-map')[0].classList.remove('disabled');
            })
            mapDisabler[0].addEventListener('click', function (e) {
                document.getElementsByClassName('card-overlay')[0].classList.remove('disabled');
                document.getElementsByClassName('card-center')[0].classList.remove('disabled');
                document.getElementsByClassName('hide-map')[0].classList.add('disabled');
            })
        }

        //To Do List
        var toDoList = document.querySelectorAll('.todo-list a');
        toDoList.forEach(el => el.addEventListener('click', e => {
            el.classList.toggle('opacity-50');
            el.querySelector('i:last-child').classList.toggle('far')
            el.querySelector('i:last-child').classList.toggle('fa')
            el.querySelector('i:last-child').classList.toggle('fa-check-square')
            el.querySelector('i:last-child').classList.toggle('fa-square')
            el.querySelector('i:last-child').classList.toggle('color-green-dark')
        }));

        //Setting Sidebar Widths
        var menus = document.querySelectorAll('.menu');
        if (menus.length) {
            var menuSidebar = document.querySelectorAll('.menu-box-left, .menu-box-right');
            menuSidebar.forEach(function (e) {
                if (e.getAttribute('data-menu-width') === "cover") {
                    e.style.width = '100%'
                } else {
                    e.style.width = (e.getAttribute('data-menu-width')) + 'px'
                }
            })
            var menuSheets = document.querySelectorAll('.menu-box-bottom, .menu-box-top, .menu-box-modal');
            menuSheets.forEach(function (e) {
                if (e.getAttribute('data-menu-width') === "cover") {
                    e.style.width = '100%'
                    e.style.height = '100%'
                } else {
                    e.style.width = (e.getAttribute('data-menu-width')) + 'px'
                    e.style.height = (e.getAttribute('data-menu-height')) + 'px'
                }
            })

            //Opening Menus
            var menuOpen = document.querySelectorAll('[data-menu]');
            var wrappers = document.querySelectorAll('.header, #footer-bar, .page-content');

            menuOpen.forEach(el => el.addEventListener('click', e => {
                //Close Existing Opened Menus
                const activeMenu = document.querySelectorAll('.menu-active');
                for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
                //Open Clicked Menu
                var menuData = el.getAttribute('data-menu');
                document.getElementById(menuData).classList.add('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
                //Check and Apply Effects
                var menu = document.getElementById(menuData);
                var menuEffect = menu.getAttribute('data-menu-effect');
                var menuLeft = menu.classList.contains('menu-box-left');
                var menuRight = menu.classList.contains('menu-box-right');
                var menuTop = menu.classList.contains('menu-box-top');
                var menuBottom = menu.classList.contains('menu-box-bottom');
                var menuWidth = menu.offsetWidth;
                var menuHeight = menu.offsetHeight;

                if (menuEffect === "menu-push") {
                    var menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
                    if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth + "px)" } }
                    if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth + "px)" } }
                    if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight + "px)" } }
                    if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight + "px)" } }
                }
                if (menuEffect === "menu-parallax") {
                    var menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
                    if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth / 10 + "px)" } }
                    if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth / 10 + "px)" } }
                    if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight / 5 + "px)" } }
                    if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight / 5 + "px)" } }
                }
            }));

            //Closing Menus
            const menuClose = document.querySelectorAll('.close-menu, .menu-hider');
            menuClose.forEach(el => el.addEventListener('click', e => {
                const activeMenu = document.querySelectorAll('.menu-active');
                for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
                for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + 0 + "px)" }
                var iframes = document.querySelectorAll('iframe');
                iframes.forEach(el => { var hrefer = el.getAttribute('src'); el.setAttribute('newSrc', hrefer); el.setAttribute('src', ''); var newSrc = el.getAttribute('newSrc'); el.setAttribute('src', newSrc) });
            }));
        }


        //Back Button
        const backButton = document.querySelectorAll('[data-back-button]');
        if (backButton.length) {
            backButton.forEach(el => el.addEventListener('click', e => {
                e.stopPropagation;
                e.preventDefault;
                window.history.go(-1);
            }));
        }


        //Back to Top
        const backToTop = document.querySelectorAll('.back-to-top-icon, .back-to-top-badge, .back-to-top');
        if (backToTop.length) {
            backToTop.forEach(el => el.addEventListener('click', e => {
                window.scrollTo({ top: 0, behavior: `smooth` })
            }));
        }

        //Check iOS Version and add min-ios15 class if higher or equal to iOS15
        function iOSversion() {
            let d, v;
            if (/iP(hone|od|ad)/.test(navigator.platform)) {
                v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                d = { status: true, version: parseInt(v[1], 10), info: parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10) };
            } else { d = { status: false, version: false, info: '' } }
            return d;
        }
        let iosVer = iOSversion();
        if (iosVer.version > 14) { document.querySelectorAll('#page')[0].classList.add('min-ios15'); }

        //Card Extender
        const cards = document.getElementsByClassName('card');
        function card_extender() {
            var headerHeight, footerHeight, headerOnPage;
            var headerOnPage = document.querySelectorAll('.header:not(.header-transparent)')[0];
            var footerOnPage = document.querySelectorAll('#footer-bar')[0];

            headerOnPage ? headerHeight = document.querySelectorAll('.header')[0].offsetHeight : headerHeight = 0
            footerOnPage ? footerHeight = document.querySelectorAll('#footer-bar')[0].offsetHeight : footerHeight = 0

            for (let i = 0; i < cards.length; i++) {
                if (cards[i].getAttribute('data-card-height') === "cover") {
                    if (window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.outerHeight; }
                    if (!window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.innerHeight; }
                    //Fix for iOS 15 pages with data-height="cover"
                    var coverHeight = windowHeight + 'px';
                    // - Remove this for iOS 14 issues - var coverHeight = windowHeight - headerHeight - footerHeight + 'px';
                }
                if (cards[i].hasAttribute('data-card-height')) {
                    var getHeight = cards[i].getAttribute('data-card-height');
                    cards[i].style.height = getHeight + 'px';
                    if (getHeight === "cover") {
                        var totalHeight = getHeight
                        cards[i].style.height = coverHeight
                    }
                }
            }
        }

        if (cards.length) {
            card_extender();
            window.addEventListener("resize", card_extender);
        }

        //Set Background and Highlight
        var pageBackground = localStorage.getItem(pwaName + '-Gradient');
        if (pageBackground) { document.body.setAttribute('data-gradient', 'body-' + pageBackground + ''); }

        //Dark Mode
        const toggleDark = document.querySelectorAll('[data-toggle-theme]');
        function activateDarkMode() {
            document.body.classList.add('theme-dark');
            document.body.classList.remove('theme-light', 'detect-theme');
            for (let i = 0; i < toggleDark.length; i++) { toggleDark[i].checked = "checked" };
            localStorage.setItem(pwaName + '-Theme', 'dark-mode');
        }
        function activateLightMode() {
            document.body.classList.add('theme-light');
            document.body.classList.remove('theme-dark', 'detect-theme');
            for (let i = 0; i < toggleDark.length; i++) { toggleDark[i].checked = false };
            localStorage.setItem(pwaName + '-Theme', 'light-mode');
        }
        function removeTransitions() { var falseTransitions = document.querySelectorAll('.btn, .header, #footer-bar, .menu-box, .menu-active'); for (let i = 0; i < falseTransitions.length; i++) { falseTransitions[i].style.transition = "all 0s ease"; } }
        function addTransitions() { var trueTransitions = document.querySelectorAll('.btn, .header, #footer-bar, .menu-box, .menu-active'); for (let i = 0; i < trueTransitions.length; i++) { trueTransitions[i].style.transition = ""; } }

        function setColorScheme() {
            const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
            const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches
            const isNoPreference = window.matchMedia("(prefers-color-scheme: no-preference)").matches
            window.matchMedia("(prefers-color-scheme: dark)").addListener(e => e.matches && activateDarkMode())
            window.matchMedia("(prefers-color-scheme: light)").addListener(e => e.matches && activateLightMode())
            if (isDarkMode) activateDarkMode();
            if (isLightMode) activateLightMode();
        }

        //Activating Dark Mode
        const darkModeSwitch = document.querySelectorAll('[data-toggle-theme]')
        darkModeSwitch.forEach(el => el.addEventListener('click', e => {
            if (document.body.className == "theme-light") { removeTransitions(); activateDarkMode(); }
            else if (document.body.className == "theme-dark") { removeTransitions(); activateLightMode(); }
            setTimeout(function () { addTransitions(); }, 350);
        }));

        //Set Color Based on Remembered Preference.
        if (localStorage.getItem(pwaName + '-Theme') == "dark-mode") { for (let i = 0; i < toggleDark.length; i++) { toggleDark[i].checked = "checked" }; document.body.className = 'theme-dark'; }
        if (localStorage.getItem(pwaName + '-Theme') == "light-mode") { document.body.className = 'theme-light'; } if (document.body.className == "detect-theme") { setColorScheme(); }

        //Detect Dark/Light Mode
        const darkModeDetect = document.querySelectorAll('.detect-dark-mode');
        darkModeDetect.forEach(el => el.addEventListener('click', e => {
            document.body.classList.remove('theme-light', 'theme-dark');
            document.body.classList.add('detect-theme')
            setTimeout(function () { setColorScheme(); }, 50)
        }))


        //Accordion Rotate
        const accordionBtn = document.querySelectorAll('.accordion-btn');
        if (accordionBtn.length) {
            accordionBtn.forEach(el => el.addEventListener('click', event => {
                el.querySelector('i:last-child').classList.toggle('fa-rotate-180');
            }));
        }

        //Link List Toggle
        var linkListToggle = document.querySelectorAll('[data-trigger-switch]:not([data-toggle-theme])');
        if (linkListToggle.length) {
            linkListToggle.forEach(el => el.addEventListener('click', event => {
                var switchData = el.getAttribute('data-trigger-switch');
                var getCheck = document.getElementById(switchData);
                getCheck.checked ? getCheck.checked = false : getCheck.checked = true;
            }))
        }

        //Classic Toggle
        var classicToggle = document.querySelectorAll('.classic-toggle');
        if (classicToggle.length) {
            classicToggle.forEach(el => el.addEventListener('click', event => {
                el.querySelector('i:last-child').classList.toggle('fa-rotate-180');
                el.querySelector('i:last-child').style.transition = "all 250ms ease"
            }))
        }

        //Toasts
        var toastTrigger = document.querySelectorAll('[data-toast]');
        if (toastTrigger.length) {
            toastTrigger.forEach(el => el.addEventListener('click', event => {
                var toastData = el.getAttribute('data-toast')
                var notificationToast = document.getElementById(toastData);
                var notificationToast = new bootstrap.Toast(notificationToast);
                notificationToast.show();
            }));
        }

        7
        //Dropdown
        var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
        if (dropdownElementList.length) {
            var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
                return new bootstrap.Dropdown(dropdownToggleEl);
            })
        }
        7

        //Vibrate API
        var vibrateButton = document.querySelectorAll('[data-vibrate]');
        if (vibrateButton.length) {
            var startVibrating = document.getElementsByClassName('start-vibrating')[0];
            var stopVibrating = document.getElementsByClassName('stop-vibrating')[0];

            startVibrating.addEventListener('click', function () {
                var vibrateTime = document.getElementsByClassName('vibrate-demo')[0].value;
                window.navigator.vibrate(vibrateTime);
            })
            stopVibrating.addEventListener('click', function () {
                window.navigator.vibrate(0);
            })
            vibrateButton.forEach(el => el.addEventListener('click', e => {
                var vibrateTime = el.getAttribute('data-vibrate');
                window.navigator.vibrate(vibrateTime);
            }));
        }

        //Contact Form
        var contactForm = document.querySelectorAll('.contact-form');
        if (contactForm.length) {
            var form = document.getElementById('contactForm');
            form.onsubmit = function (e) {
                // Stop the regular form submission
                e.preventDefault();

                //Validate Fields
                var nameField = document.getElementById('contactNameField');
                var mailField = document.getElementById('contactEmailField');
                var textField = document.getElementById('contactMessageTextarea');
                var validateMail = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                if (nameField.value === '') {
                    form.setAttribute('data-form', 'invalid');
                    nameField.classList.add('border-red-dark');
                    document.getElementById('validator-name').classList.remove('disabled');
                } else {
                    form.setAttribute('data-form', 'valid');
                    document.getElementById('validator-name').classList.add('disabled');
                    nameField.classList.remove('border-red-dark');
                }
                if (mailField.value === '') {
                    form.setAttribute('data-form', 'invalid');
                    mailField.classList.add('border-red-dark');
                    document.getElementById('validator-mail1').classList.remove('disabled');
                } else {
                    document.getElementById('validator-mail1').classList.add('disabled');
                    if (!validateMail.test(mailField.value)) {
                        form.setAttribute('data-form', 'invalid');
                        mailField.classList.add('border-red-dark');
                        document.getElementById('validator-mail2').classList.remove('disabled');
                    } else {
                        form.setAttribute('data-form', 'valid');
                        document.getElementById('validator-mail2').classList.add('disabled');
                        mailField.classList.remove('border-red-dark');
                    }
                }
                if (textField.value === '') {
                    form.setAttribute('data-form', 'invalid');
                    textField.classList.add('border-red-dark');
                    document.getElementById('validator-text').classList.remove('disabled');
                } else {
                    form.setAttribute('data-form', 'valid');
                    document.getElementById('validator-text').classList.add('disabled');
                    textField.classList.remove('border-red-dark')
                }

                if (form.getAttribute('data-form') === 'valid') {
                    document.querySelectorAll('.form-sent')[0].classList.remove('disabled');
                    document.querySelectorAll('.contact-form')[0].classList.add('disabled');
                    // Collect the form data while iterating over the inputs
                    var data = {};
                    for (let i = 0, ii = form.length; i < ii; ++i) {
                        let input = form[i];
                        if (input.name) {
                            data[input.name] = input.value;
                        }
                    }
                    // Construct an HTTP request
                    var xhr = new XMLHttpRequest();
                    xhr.open(form.method, form.action, true);
                    xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
                    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
                    // Send the collected data as JSON
                    xhr.send(JSON.stringify(data));
                    // Callback function
                    xhr.onloadend = function (response) { if (response.target.status === 200) { console.log('Form Submitted') } };
                }
            };
        }

        //Collapse Flip Icon
        var collapseBtn = document.querySelectorAll('[data-bs-toggle="collapse"]:not(.no-effect)');
        if (collapseBtn.length) {
            collapseBtn.forEach(el => el.addEventListener('click', e => {
                if (el.querySelectorAll('i').length) {
                    el.querySelector('i').classList.toggle('fa-rotate-180')
                };
            }));
        }

        //Tabs
        var tabTrigger = document.querySelectorAll('.tab-controls a');
        if (tabTrigger.length) {
            tabTrigger.forEach(function (e) {
                if (e.hasAttribute('data-active')) {
                    var highlightColor = e.parentNode.getAttribute('data-highlight');
                    e.classList.add(highlightColor);
                    e.classList.add('no-click');
                }
            });
            tabTrigger.forEach(el => el.addEventListener('click', e => {
                var highlightColor = el.parentNode.getAttribute('data-highlight');
                var tabParentGroup = el.parentNode.querySelectorAll('a');
                tabParentGroup.forEach(function (e) {
                    e.classList.remove(highlightColor);
                    e.classList.remove('no-click');
                });
                el.classList.add(highlightColor);
                el.classList.add('no-click');
            }));
        }


        //Extending Menu Functions
        function menu(menuName, menuFunction, menuTimeout) {
            setTimeout(function () {
                if (menuFunction === "show") {
                    return document.getElementById(menuName).classList.add('menu-active'),
                        document.querySelectorAll('.menu-hider')[0].classList.add('menu-active')
                } else {
                    return document.getElementById(menuName).classList.remove('menu-active'),
                        document.querySelectorAll('.menu-hider')[0].classList.remove('menu-active')
                }
            }, menuTimeout)
        }

        var autoActivate = document.querySelectorAll('[data-auto-activate]');
        if (autoActivate.length) {
            setTimeout(function () {
                autoActivate[0].classList.add('menu-active');
                menuHider[0].classList.add('menu-active');
            }, 60);
        }

        //Copyright Year
        var copyrightYear = document.getElementById('copyright-year');
        if (copyrightYear) {
            var dteNow = new Date();
            const intYear = dteNow.getFullYear();
            copyrightYear.textContent = intYear;
        }



        //Creating Offline Alert Messages
        var addOfflineClasses = document.querySelectorAll('.offline-message');
        if (!addOfflineClasses.length) {
            const offlineAlert = document.createElement('p');
            const onlineAlert = document.createElement('p');
            offlineAlert.className = 'offline-message bg-red-dark color-white';
            offlineAlert.textContent = 'No internet connection detected';
            onlineAlert.className = 'online-message bg-green-dark color-white';
            onlineAlert.textContent = 'You are back online';
            document.getElementsByTagName('body')[0].appendChild(offlineAlert);
            document.getElementsByTagName('body')[0].appendChild(onlineAlert);
        }

        //Online / Offline Settings
        //Activating and Deactivating Links Based on Online / Offline State
        function offlinePage() {
            //Enable the code below to disable offline mode
            //var anchorsDisabled = document.querySelectorAll('a');
            //anchorsDisabled.forEach(function(e){
            //    var hrefs = e.getAttribute('href');
            //    if(hrefs.match(/.html/)){e.classList.add('show-offline'); e.setAttribute('data-link',hrefs); e.setAttribute('href','#');}
            //});
            var showOffline = document.querySelectorAll('.show-offline');
            showOffline.forEach(el => el.addEventListener('click', event => {
                document.getElementsByClassName('offline-message')[0].classList.add('offline-message-active');
                setTimeout(function () { document.getElementsByClassName('offline-message')[0].classList.remove('offline-message-active'); }, 1500)
            }));
        }
        function onlinePage() {
            var anchorsEnabled = document.querySelectorAll('[data-link]');
            anchorsEnabled.forEach(function (e) {
                var hrefs = e.getAttribute('data-link');
                if (hrefs.match(/.html/)) { e.setAttribute('href', hrefs); e.removeAttribute('data-link', ''); }
            });
        }

        //Defining Offline/Online Variables
        var offlineMessage = document.getElementsByClassName('offline-message')[0];
        var onlineMessage = document.getElementsByClassName('online-message')[0];


        //Online / Offine Status
        function isOnline() {
            onlinePage(); onlineMessage.classList.add('online-message-active');
            setTimeout(function () { onlineMessage.classList.remove('online-message-active'); }, 2000)
            console.info('Connection: Online');
        }

        function isOffline() {
            offlinePage(); offlineMessage.classList.add('offline-message-active');
            setTimeout(function () { offlineMessage.classList.remove('offline-message-active'); }, 2000)
            console.info('Connection: Offline');
        }

        var simulateOffline = document.querySelectorAll('.simulate-offline');
        var simulateOnline = document.querySelectorAll('.simulate-online');
        if (simulateOffline.length) {
            simulateOffline[0].addEventListener('click', function () { isOffline() });
            simulateOnline[0].addEventListener('click', function () { isOnline() });
        }

        //Check if Online / Offline
        function updateOnlineStatus(event) { var condition = navigator.onLine ? "online" : "offline"; isOnline(); }
        function updateOfflineStatus(event) { isOffline(); }
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOfflineStatus);

        //iOS Badge
        const iOSBadge = document.querySelectorAll('.simulate-iphone-badge');
        iOSBadge.forEach(el => el.addEventListener('click', e => {
            document.getElementsByClassName('add-to-home')[0].classList.add('add-to-home-visible', 'add-to-home-ios');
            document.getElementsByClassName('add-to-home')[0].classList.remove('add-to-home-android');
        }));

        //Android Badge
        const AndroidBadge = document.querySelectorAll('.simulate-android-badge');
        AndroidBadge.forEach(el => el.addEventListener('click', e => {
            document.getElementsByClassName('add-to-home')[0].classList.add('add-to-home-visible', 'add-to-home-android');
            document.getElementsByClassName('add-to-home')[0].classList.remove('add-to-home-ios');
        }));

        //Remove Add to Home Badge
        const addToHomeBadgeClose = document.querySelectorAll('.add-to-home');
        addToHomeBadgeClose.forEach(el => el.addEventListener('click', e => {
            document.getElementsByClassName('add-to-home')[0].classList.remove('add-to-home-visible');
        }));

        //Detecting Mobile OS
        let isMobile = {
            Android: function () { return navigator.userAgent.match(/Android/i); },
            iOS: function () { return navigator.userAgent.match(/iPhone|iPad|iPod/i); },
            any: function () { return (isMobile.Android() || isMobile.iOS()); }
        };

        const androidDev = document.getElementsByClassName('show-android');
        const iOSDev = document.getElementsByClassName('show-ios');
        const noDev = document.getElementsByClassName('show-no-device');

        if (!isMobile.any()) {
            for (let i = 0; i < iOSDev.length; i++) { iOSDev[i].classList.add('disabled'); }
            for (let i = 0; i < androidDev.length; i++) { androidDev[i].classList.add('disabled'); }
        }
        if (isMobile.iOS()) {
            document.querySelectorAll('#page')[0].classList.add('device-is-ios');
            for (let i = 0; i < noDev.length; i++) { noDev[i].classList.add('disabled'); }
            for (let i = 0; i < androidDev.length; i++) { androidDev[i].classList.add('disabled'); }
        }
        if (isMobile.Android()) {
            document.querySelectorAll('#page')[0].classList.add('device-is-android');
            for (let i = 0; i < iOSDev.length; i++) { iOSDev[i].classList.add('disabled'); }
            for (let i = 0; i < noDev.length; i++) { noDev[i].classList.add('disabled'); }
        }
        /*
                //OTP Boxes
                var otp = document.querySelectorAll('.otp');
                if (otp[0]) {
                    otp.forEach(el => {
                        el.addEventListener('focus', (e) => { el.value = ""; })
                        el.addEventListener('input', (e) => { el.nextElementSibling ? el.nextElementSibling.focus() : el.blur(); });
                    });
                }
        */

        //PWA Settings
        if (isPWA === true) {
            var checkPWA = document.getElementsByTagName('html')[0];
            if (!checkPWA.classList.contains('isPWA')) {
                if ('serviceWorker' in navigator) {
                    window.addEventListener('load', function () {
                        navigator.serviceWorker.register(pwaLocation, { scope: pwaScope }).then(function (registration) { registration.update(); })
                    });
                }

                //Setting Timeout Before Prompt Shows Again if Dismissed
                var hours = pwaRemind * 24; // Reset when storage is more than 24hours
                var now = Date.now();
                var setupTime = localStorage.getItem(pwaName + '-PWA-Timeout-Value');
                if (setupTime == null) {
                    localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
                } else if (now - setupTime > hours * 60 * 60 * 1000) {
                    localStorage.removeItem(pwaName + '-PWA-Prompt')
                    localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
                }


                const pwaClose = document.querySelectorAll('.pwa-dismiss');
                pwaClose.forEach(el => el.addEventListener('click', e => {
                    const pwaWindows = document.querySelectorAll('#menu-install-pwa-android, #menu-install-pwa-ios');
                    for (let i = 0; i < pwaWindows.length; i++) { pwaWindows[i].classList.remove('menu-active'); }
                    localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
                    localStorage.setItem(pwaName + '-PWA-Prompt', 'install-rejected');
                    console.log('PWA Install Rejected. Will Show Again in ' + (pwaRemind) + ' Days')
                }));

                //Trigger Install Prompt for Android
                const pwaWindows = document.querySelectorAll('#menu-install-pwa-android, #menu-install-pwa-ios');
                if (pwaWindows.length) {
                    if (isMobile.Android()) {
                        if (localStorage.getItem(pwaName + '-PWA-Prompt') != "install-rejected") {
                            function showInstallPrompt() {
                                setTimeout(function () {
                                    if (!window.matchMedia('(display-mode: fullscreen)').matches) {
                                        console.log('Triggering PWA Window for Android')
                                        document.getElementById('menu-install-pwa-android').classList.add('menu-active');
                                        document.querySelectorAll('.menu-hider')[0].classList.add('menu-active');
                                    }
                                }, 3500);
                            }
                            var deferredPrompt;
                            window.addEventListener('beforeinstallprompt', (e) => {
                                e.preventDefault();
                                deferredPrompt = e;
                                showInstallPrompt();
                            });
                        }
                        const pwaInstall = document.querySelectorAll('.pwa-install');
                        pwaInstall.forEach(el => el.addEventListener('click', e => {
                            deferredPrompt.prompt();
                            deferredPrompt.userChoice
                                .then((choiceResult) => {
                                    if (choiceResult.outcome === 'accepted') {
                                        console.log('Added');
                                    } else {
                                        localStorage.setItem(pwaName + '-PWA-Timeout-Value', now);
                                        localStorage.setItem(pwaName + '-PWA-Prompt', 'install-rejected');
                                        setTimeout(function () {
                                            if (!window.matchMedia('(display-mode: fullscreen)').matches) {
                                                document.getElementById('menu-install-pwa-android').classList.remove('menu-active');
                                                document.querySelectorAll('.menu-hider')[0].classList.remove('menu-active');
                                            }
                                        }, 50);
                                    }
                                    deferredPrompt = null;
                                });
                        }));
                        window.addEventListener('appinstalled', (evt) => {
                            document.getElementById('menu-install-pwa-android').classList.remove('menu-active');
                            document.querySelectorAll('.menu-hider')[0].classList.remove('menu-active');
                        });
                    }
                    //Trigger Install Guide iOS
                    if (isMobile.iOS()) {
                        if (localStorage.getItem(pwaName + '-PWA-Prompt') != "install-rejected") {
                            setTimeout(function () {
                                if (!window.matchMedia('(display-mode: fullscreen)').matches) {
                                    console.log('Triggering PWA Window for iOS');
                                    document.getElementById('menu-install-pwa-ios').classList.add('menu-active');
                                    document.querySelectorAll('.menu-hider')[0].classList.add('menu-active');
                                }
                            }, 3500);
                        }
                    }
                }
            }
            checkPWA.setAttribute('class', 'isPWA');
        }

        //End of isPWA
        if (pwaNoCache === true) {
            sessionStorage.clear()
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName);
                });
            });
        }

        //Lazy Loading
        var lazyLoad = new LazyLoad();

    }

    //Fix Scroll for AJAX pages.
    if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual';

    //End of Init Template
    if (isAJAX === true) {
        if (window.location.protocol !== "file:") {
            const options = {
                containers: ["#page"],
                cache: true,
                animateHistoryBrowsing: false,
                plugins: [
                    new SwupPreloadPlugin()
                ],
                linkSelector: 'a:not(.external-link):not(.default-link):not([href^="https"]):not([href^="http"]):not([data-gallery])'
            };
            const swup = new Swup(options);
            document.addEventListener('swup:pageView', (e) => { init_template(); })
        }
    }

    init_template();
});

